import 'swiper/css';
import 'swiper/css/scrollbar';
import { Mousewheel, Scrollbar } from 'swiper/modules';
import { SwiperProps } from 'swiper/react';

export const commonSwiperConfigs: SwiperProps = {
  spaceBetween: 16,
  slidesPerView: 'auto',
  centeredSlides: false,
  grabCursor: true,
  mousewheel: true,
  scrollbar: true,
  modules: [Mousewheel, Scrollbar],
  style: { paddingBottom: '2rem' },
};
