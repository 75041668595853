import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { CatalogueBrand, CatalogueSeller } from 'src/logic/models/catalogue/catalogue-content';
import { getAllTabData } from 'src/logic/services/catalogue';
import { commonOptions, useCommonKeyProps } from './common';

type CatalogueAllResponse = {
  FeaturedBrands: CatalogueBrand[];
  FeaturedSellers: CatalogueSeller[] | null;
};

export const useCatalogueAllTabContent = (
  search?: string,
  options: UseQueryOptions<CatalogueAllResponse, Error> | undefined = {}
): UseQueryResult<CatalogueAllResponse, Error> => {
  const keyProps = useCommonKeyProps();

  return useQuery<CatalogueAllResponse, Error>({
    queryKey: ['CATALOGUE_All', keyProps, search],
    queryFn: async () => {
      const { data } = await getAllTabData({
        search,
        environmentFilters: keyProps.environmentFilters,
      });
      return {
        FeaturedBrands: data.brands,
        FeaturedSellers: data.sellers,
      };
    },
    ...commonOptions,
    ...options,
  });
};
