import { SvgIcon, SvgIconProps } from '@mui/material';

export const AiFilledIcon: React.FC<SvgIconProps> = props => (
  <SvgIcon width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#clip0_38056_53401)'>
      <path
        d='M6.7767 3.22084L5.77146 0.65997C5.6878 0.446677 5.38698 0.446677 5.30332 0.65997L4.29808 3.22084C4.01106 3.95194 3.43524 4.53056 2.70768 4.81897L0.159197 5.8291C-0.0530655 5.91317 -0.0530655 6.21544 0.159197 6.29951L2.70768 7.30964C3.43524 7.59805 4.01106 8.17667 4.29808 8.90777L5.30332 11.4686C5.38698 11.6819 5.6878 11.6819 5.77146 11.4686L6.7767 8.90777C7.06372 8.17667 7.63954 7.59805 8.36711 7.30964L10.9156 6.29951C11.1278 6.21544 11.1278 5.91317 10.9156 5.8291L8.36711 4.81897C7.63954 4.53056 7.06372 3.95194 6.7767 3.22084Z'
        fill='#0074FF'
      />
      <path
        opacity='0.34'
        d='M16.081 3.70309L15.632 2.55971C15.5946 2.46447 15.4602 2.46447 15.4228 2.55971L14.9738 3.70309C14.8457 4.02952 14.5885 4.28797 14.2636 4.41676L13.1258 4.86794C13.031 4.9055 13.031 5.04054 13.1258 5.0781L14.2636 5.52928C14.5885 5.65806 14.8457 5.91652 14.9738 6.24294L15.4228 7.38632C15.4602 7.48157 15.5946 7.48157 15.632 7.38632L16.081 6.24294C16.2091 5.91652 16.4663 5.65806 16.7912 5.52928L17.929 5.0781C18.0238 5.04054 18.0238 4.9055 17.929 4.86794L16.7912 4.41676C16.4663 4.28797 16.2091 4.02952 16.081 3.70309Z'
        fill='#0074FF'
      />
      <path
        opacity='0.7'
        d='M14.1807 12.1053L13.5355 10.462C13.4816 10.3252 13.289 10.3252 13.2351 10.462L12.5899 12.1053C12.4057 12.5744 12.0363 12.946 11.5695 13.1306L9.93416 13.779C9.79799 13.8331 9.79799 14.0267 9.93416 14.0808L11.5695 14.7292C12.0363 14.9143 12.4061 15.2855 12.5899 15.7545L13.2351 17.3978C13.289 17.5347 13.4816 17.5347 13.5355 17.3978L14.1807 15.7545C14.365 15.2855 14.7343 14.9139 15.2011 14.7292L16.8365 14.0808C16.9726 14.0267 16.9726 13.8331 16.8365 13.779L15.2011 13.1306C14.7343 12.9455 14.3645 12.5744 14.1807 12.1053Z'
        fill='#0074FF'
      />
    </g>
    <defs>
      <clipPath id='clip0_38056_53401'>
        <rect width='18' height='17' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </SvgIcon>
);
