import { request } from '@/helpers';
import { EnvironmentFilters } from 'src/logic/models/catalogue/AdvancedFilters';
import Category from 'src/logic/models/catalogue/Category';
import { GroupIndex } from 'src/logic/models/catalogue/Group';
import { PaginatedResponse } from 'src/logic/models/queries';

export const getGroups = ({
  environmentFilters = {},
}: {
  environmentFilters: EnvironmentFilters;
}): Promise<{ data: GroupIndex }> => {
  return request({ method: 'GET', url: `/product/catalog/products_services_tab`, params: { ...environmentFilters } });
};

export const getChildrenCategories = (
  parent_id: number,
  page: number,
  environmentFilters: EnvironmentFilters = {}
): Promise<{
  data: PaginatedResponse<Category>;
}> => {
  return request({
    url: `/getCategories`,
    method: 'POST',
    data: {
      parent_id,
      page,
      ...environmentFilters,
    },
  });
};

export const getCategories = ({
  page,
  search,
  environmentFilters = {},
}: {
  page: number;
  search?: string;
  environmentFilters: EnvironmentFilters;
}): Promise<{
  data: PaginatedResponse<Category>;
}> => {
  return request({
    url: `/product/category/get_sub_category`,
    method: 'GET',
    params: {
      page,
      category_type: 1,
      query: search,
      ...environmentFilters,
    },
  });
};

export const getClasses = ({
  page,
  search,
  environmentFilters = {},
}: {
  page: number;
  search?: string;
  environmentFilters: EnvironmentFilters;
}): Promise<{
  data: PaginatedResponse<Category>;
}> => {
  return request({
    url: `/product/category/get_sub_category`,
    method: 'GET',
    params: {
      page,
      category_type: 2,
      query: search,
      ...environmentFilters,
    },
  });
};

export const getTypes = ({
  page,
  search,
  environmentFilters = {},
}: {
  page: number;
  search?: string;
  environmentFilters: EnvironmentFilters;
}): Promise<{
  data: PaginatedResponse<Category>;
}> => {
  return request({
    url: `/product/category/get_sub_category`,
    method: 'GET',
    params: {
      page,
      category_type: 3,
      query: search,
      ...environmentFilters,
    },
  });
};
