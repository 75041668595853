import React from 'react';
import { CatalogueTab } from 'src/logic/models/catalogue/catalogue-content';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import AllTabContent from '../tabs/all/AllTabContent';
import BrandsTabContent from '../tabs/brands/BrandsTabContent';
import ProductsAndServicesTabContent from '../tabs/products-and-services/ProductsAndServicesTabContent';
import SellersTabContent from '../tabs/sellers/SellersTabContent';

import { ProductManagerEnv } from '@/config';
import { useProcurementStore } from '@/zustand';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import SearchAllTabContent from '../tabs/all/SearchAllTabContent';
import SearchBrandsTabContent from '../tabs/brands/SearchBrandsTabContent';
import SearchProductsAndServicesTabContent from '../tabs/products-and-services/SearchProductsAndServicesTabContent';
import SearchSellersTabContent from '../tabs/sellers/SearchSellersTabContent';
import { ShapesSVG } from '@/icons';

type Tab = {
  id: CatalogueTab;
  label: string;
  content: React.ReactNode;
  searchContent: React.ReactNode;
  icon?: React.ReactNode;
};

const iconStyles = {
  fontSize: '16px',
  transform: 'translateY(3px)',
};

export const useCatalogueTabs = () => {
  const { data: envData } = useProcurementStore(state => state.productFormManagerProperties);
  const env = envData.environment?.type;
  const shouldShowSellersTab =
    env !== ProductManagerEnv.purchase_order && env !== ProductManagerEnv.edit_po_from_scratch;

  return React.useMemo<Tab[]>(() => {
    const tabs: Tab[] = [
      {
        id: 'ALL',
        label: 'All',
        content: <AllTabContent />,
        searchContent: <SearchAllTabContent />,
        icon: <SearchIcon sx={iconStyles} />,
      },
      {
        id: 'PRODUCTS_AND_SERVICES',
        label: 'Products & Services',
        content: <ProductsAndServicesTabContent />,
        searchContent: <SearchProductsAndServicesTabContent />,
        icon: <ShapesSVG />,
      },
      {
        id: 'BRANDS',
        label: 'Brands',
        content: <BrandsTabContent />,
        searchContent: <SearchBrandsTabContent />,
        icon: <LocalOfferOutlinedIcon sx={iconStyles} />,
      },
    ];

    if (shouldShowSellersTab) {
      tabs.push({
        id: 'SELLERS',
        label: 'Sellers',
        content: <SellersTabContent />,
        searchContent: <SearchSellersTabContent />,
        icon: <ShoppingCartOutlinedIcon sx={iconStyles} />,
      });
    }

    return tabs;
  }, [shouldShowSellersTab]);
};

export const useTabIndex = () => {
  const tabs = useCatalogueTabs();
  const { selectedTab } = useCatalogueSlice(state => state.catalogueProperties);

  return React.useMemo(() => {
    return tabs?.findIndex(tab => tab.id === selectedTab) ?? 0;
  }, [tabs, selectedTab]);
};
