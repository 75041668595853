import { ExpandableListItemWithIcon, Logo } from '@/components';
import { AuthenticateStatus } from '@/config';
import { LogoPicSVG } from '@/icons';
import { useDrawerStore, useProcurementStore, useUserData } from '@/zustand';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import * as React from 'react';
import useEnvironmentFilters from 'src/logic/zustand/Filters/hooks/use-environment-filters';
import { sm } from 'src/site-map';
import { Drawer_PAGES_CONFIG } from '../drawer-config';
import { DrawerFooter, DrawerFooterWebsiteInfo } from './drawer-footer';
import { SideDrawerItem } from './drawer-item';
import { Drawer, ListStack, SubMenuList } from './drawer-styled-components';
import { DrawerSubMenuContent } from './drawer-submenu-content';



export const SideDrawer = (props: any) => {
  const { isDrawerOpen, isDrawerSubMenuOpen, openDrawer, closeDrawer, isFooter } = useDrawerStore()

  // auto close sidebar when page is changed
  const { pathname } = useRouter();
  React.useEffect(() => {
    closeDrawer();
  }, [pathname]);
  const isSeller = useUserData.getState().isSeller();
  const { seller_id } = useEnvironmentFilters();

  return (
    <Box sx={{ display: 'flex', borderRight: 1, borderColor: "white", height: '100vh' }}>
      <Drawer variant="permanent" open={isDrawerOpen} isDrawerSubMenuOpen={isDrawerSubMenuOpen} sx={{ '& .MuiDrawer-paper': { border: "none", flexDirection: 'row' }, }}>
        {/* before openning the drawer */}
        <Box sx={{ backgroundColor: !isDrawerOpen && isDrawerSubMenuOpen ? 'warning.dark' : 'initial', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

          <ListStack open={isDrawerOpen} isDrawerSubMenuOpen={isDrawerSubMenuOpen}>
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2, marginBottom: 2, cursor: 'pointer' }}>
              <Link href={props.authenticatedStatus === AuthenticateStatus.AUTHENTICATED ? isSeller ? sm.portals.seller.dashboard.url : sm.portals.buyer.dashboard.url : sm.home.url} underline='none' sx={{ alignItems: 'center', marginRight: 5 }} >
                {isDrawerOpen ? <Logo /> : <LogoPicSVG />}
              </Link>
            </Box>

            {/* {Drawer_PAGES_CONFIG[props.userType].map((pageProps: any) => (
              <SideDrawerItem key={pageProps.title} drawerItemDetails={pageProps} isDrawerOpen={isDrawerOpen} />
            ))} */}
            {Drawer_PAGES_CONFIG[props.userType].map((pageProps: any) => {
              if (seller_id && pageProps.title === 'Catalogue') {
                pageProps.url = sm.catalogue.sellers.sellerId(`${seller_id}`).url;
              } else {
                if (pageProps.title === 'Catalogue') pageProps.url = sm.catalogue.index.url;
              }
              return (
                <SideDrawerItem key={pageProps.title} drawerItemDetails={pageProps} isDrawerOpen={isDrawerOpen} />
              );
            })}
          </ListStack>

          <Box>
            <DrawerFooter userType={props.userType} />
            {!isDrawerSubMenuOpen ? <DrawerControlButton openDrawer={openDrawer} closeDrawer={closeDrawer} isDrawerOpen={isDrawerOpen} isDrawerSubMenuOpen={isDrawerSubMenuOpen} />
              : <Box height={45}></Box>}
          </Box>
        </Box>
        {/* The conent shown when the drawer opens */}
        {isDrawerSubMenuOpen && <SubMenuList isDrawerSubMenuOpen={isDrawerSubMenuOpen}>
          <Stack sx={{ marginTop: '60px', height: '87%' }}>
            <DrawerSubMenuContent />
          </Stack>
          {/* <DrawerFooter /> */}
          {isFooter && <DrawerFooterWebsiteInfo />}
          <DrawerControlButton openDrawer={openDrawer} closeDrawer={closeDrawer} isDrawerOpen={isDrawerOpen || isDrawerSubMenuOpen} isDrawerSubMenuOpen={isDrawerSubMenuOpen} />
        </SubMenuList>}
      </Drawer>
      <Box sx={{ flex: 1 }}>
        {props.children}
      </Box>
    </Box>
  );
}



interface DrawerControlButtonmProps { isDrawerSubMenuOpen: boolean, isDrawerOpen: boolean, closeDrawer: () => void, openDrawer: () => void }

const DrawerControlButton: React.FC<DrawerControlButtonmProps> = ({ isDrawerOpen, isDrawerSubMenuOpen, closeDrawer, openDrawer }) => {
  const { t } = useTranslation();
  return (
    <ListItem disablePadding>
      {isDrawerOpen || isDrawerSubMenuOpen ?
        <ExpandableListItemWithIcon
          title={t("buttons.collapse_menu")}
          handleClickListItem={closeDrawer}
          isItemExpanded={true}
          isItemContainsSubItem={false}
          icon={<KeyboardDoubleArrowLeftIcon color='primary' />}
        />
        : <ExpandableListItemWithIcon
          handleClickListItem={openDrawer}
          isItemExpanded={false}
          isItemContainsSubItem={false}
          icon={<KeyboardDoubleArrowRightIcon color='primary' />}
        />
      }
    </ListItem>
  )
}
