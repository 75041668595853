import React from 'react';
import useScrollObserver from 'src/logic/hooks/use-scroll-observer';
import { CatalogueBrandsTabResponse } from 'src/logic/services/catalogue/brands';
import CatalogueBrandCard from '../../components/CatalogueBrandCard';
import LoadingSection from '../../components/LoadingSection';
import { useCatalogueBrands } from '../../queries/brands';

type IProps = {
  initialData: CatalogueBrandsTabResponse;
  sortBy: string;
  search?: string;
};

const BrandsContent = ({ initialData, sortBy, search }: IProps) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } = useCatalogueBrands({
    initialData,
    search,
    sortBy,
  });

  const { observerRef, containerRef } = useScrollObserver({
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
  });

  const brands = React.useMemo(() => {
    return data?.pages?.flatMap(page => page.data.data) ?? [];
  }, [data]);

  if (isLoading) {
    return <LoadingSection label='Loading Brands...' />;
  }

  return (
    <div
      style={{ maxHeight: '400px', overflow: 'auto', paddingInlineEnd: '.5rem' }}
      className='styled-scrollbar'
      ref={containerRef}
    >
      <div
        style={{
          marginBlock: '1rem',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
          gap: '1rem',
        }}
      >
        {brands.map(brand => (
          <CatalogueBrandCard key={brand.id} brand={brand} />
        ))}
      </div>

      {hasNextPage && (
        <div
          style={{ marginBlock: '.5rem', opacity: isFetchingNextPage ? 1 : 0, transition: 'opacity 200ms' }}
          ref={observerRef}
        >
          <LoadingSection minHeight='auto' label='Loading Brands...' loadingIndicatorSize='1rem' />
        </div>
      )}
    </div>
  );
};

export default BrandsContent;
