import { Avatar, Box, Grid, Typography } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CatalogueSeller } from 'src/logic/models/catalogue/catalogue-content';
import { getAvatarDisplayName } from 'src/logic/utils/avatar';
import { formatInteger } from 'src/logic/utils/numbers';
import { sm } from 'src/site-map';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';
import { companyAvatarStyles, sellerItemStyles } from '../../index-page/styles';
import { ProductCategoryKind } from '@/models';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';

type IProps = { seller: CatalogueSeller };

const CatalogueFeaturedSellerCard = ({ seller }: IProps) => {
  const { push } = useRouter();
  const { isCatalogueSearchPopupOpen } = useCatalogueSlice(state => state.catalogueProperties);
  const { closeCatalogueSearchPopup } = useCatalogueSlice(state => state.catalogueActions);

  return (
    <Link href={sm.catalogue.sellers.sellerId(`${seller.seller.id}`).url}>
      <Grid
        item
        display={'flex'}
        alignItems={'center'}
        gap={1}
        sx={sellerItemStyles}
        className='catalogue-border'
        p={1}
        onClick={() => isCatalogueSearchPopupOpen && closeCatalogueSearchPopup()}
      >
        {seller.image_url ? (
          <img
            src={seller.image_url}
            alt={getAvatarDisplayName(seller.seller.name)}
            style={{ width: '50px', height: '50px', objectFit: 'contain' }}
          />
        ) : (
          <Avatar sx={companyAvatarStyles} className='avatar'>
            {getAvatarDisplayName(seller.seller.name)}
          </Avatar>
        )}
        <Box display='flex' flexDirection='column' alignItems='flex-start' sx={{ flexGrow: 1, width: '100%' }}>
          <Box display='flex' alignItems='end' sx={{ width: '100%' }}>
            <Typography fontWeight={400} color='#3A3E50' fontSize={13} className='seller-name'>
              {seller.search_metadata?._highlightResult ? (
                <span dangerouslySetInnerHTML={{ __html: seller.search_metadata._highlightResult.name.value }}></span>
              ) : (
                seller.seller.name
              )}

              {seller.is_private && (
                <PrivacyButton
                  disableText
                  iconHeight='20px'
                  buttonOnlyStyles={{ paddingLeft: '2px', transform: 'translateY(2px)' }}
                />
              )}
            </Typography>
          </Box>
          <Typography fontSize={12} color='text.secondary'>
            <span
              onClick={e => {
                e.stopPropagation();
                isCatalogueSearchPopupOpen && closeCatalogueSearchPopup()
                seller.number_of_products && seller.number_of_products !== 0 && push({
                  pathname: sm.catalogue.sellers.sellerId(`${seller.seller.id}`).url,
                  query: { 'category-kind': ProductCategoryKind.PRODUCT },
                });
              }}
              style={{ cursor: seller.number_of_products !== 0 ? 'pointer' : 'default' }}
              className={seller.number_of_products !== 0 ? 'underline-on-hover color-blue-on-hover' : ''}
            >
              {formatInteger(seller.number_of_products)} Products
            </span>
            <span
              onClick={e => {
                e.stopPropagation();
                isCatalogueSearchPopupOpen && closeCatalogueSearchPopup()
                seller.number_of_services && seller.number_of_services !== 0 && push({
                  pathname: sm.catalogue.sellers.sellerId(`${seller.seller.id}`).url,
                  query: { 'category-kind': ProductCategoryKind.SERVICE },
                });
              }}
              style={{ cursor: seller.number_of_services !== 0 ? 'pointer' : 'default', paddingInlineStart: '.5rem' }}
              className={seller.number_of_services !== 0 ? 'underline-on-hover color-blue-on-hover' : ''}
            >
              {formatInteger(seller.number_of_services)} Services
            </span>
          </Typography>
        </Box>
      </Grid>
    </Link>
  );
};

export default CatalogueFeaturedSellerCard;
