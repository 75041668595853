import { request } from '@/helpers';
import { Product } from '@/models';
import { EnvironmentFilters } from 'src/logic/models/catalogue/AdvancedFilters';
import { CatalogueBrand, CatalogueSeller } from 'src/logic/models/catalogue/catalogue-content';
import Category from 'src/logic/models/catalogue/Category';
import { PaginatedResponse } from 'src/logic/models/queries';

export type CatalogueSearchResponse = {
  groups: Category[];
  categories: PaginatedResponse<Category>;
  classes: PaginatedResponse<Category>;
  types: PaginatedResponse<Category>;
  products: Product[];
  brands: CatalogueBrand[];
  sellers: CatalogueSeller[];
};

export const getSearchCatalogue = ({
  search,
  environmentFilters = {},
}: {
  search?: string;
  environmentFilters?: EnvironmentFilters;
}): Promise<{ data: CatalogueSearchResponse }> => {
  return request({
    url: `/product/catalog/all_tab`,
    method: 'GET',
    params: {
      query: search,
      ...environmentFilters,
    },
  });
};
