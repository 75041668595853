import { Box, Grid } from '@mui/material';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import SectionDivider from '../../components/SectionDivider';
import SuggestedProductsAndServicesSection from '../@shared/SuggestedProductsAndServicesSection';
import CategoriesSection from './category-tree-sections/CategoriesSection';
import ClassesSection from './category-tree-sections/ClassesSection';
import GroupsSection from './category-tree-sections/GroupsSection';
import TypesSection from './category-tree-sections/TypesSection';
import ProductsAndServicesForType from './ProductsAndServicesForType';

const ProductsAndServicesTabContent = () => {
  const { selectedGroupId, selectedCategoryId, selectedClassId, selectedType } = useCatalogueSlice(
    state => state.catalogueProperties
  );

  return (
    <div>
      <GroupsSection />

      {selectedGroupId !== null && (
        <>
          <SectionDivider />

          <Grid container spacing={4} minHeight={200}>
            <Grid item md={4}>
              <CategoriesSection />
            </Grid>
            <Grid item md={4}>
              {selectedCategoryId !== null && <ClassesSection />}
            </Grid>
            <Grid item md={4}>
              {selectedClassId !== null && <TypesSection />}
            </Grid>
          </Grid>
        </>
      )}

      {selectedType !== null && (
        <>
          <SectionDivider />

          <Box sx={{ py: 1 }}>
            <ProductsAndServicesForType />
          </Box>
        </>
      )}

      <SuggestedProductsAndServicesSection />
    </div>
  );
};

export default ProductsAndServicesTabContent;
