import { useEffect, useRef } from 'react';

interface UseScrollObserverOptions {
  onIntersect: () => void;
  threshold?: number;
  root?: HTMLElement | null;
  rootMargin?: string;
}

const useScrollObserver = ({
  onIntersect,
  threshold = 1.0,
  root = null,
  rootMargin = '0px',
}: UseScrollObserverOptions) => {
  const observerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          onIntersect();
        }
      },
      { root, rootMargin, threshold }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [onIntersect, root, rootMargin, threshold]);

  return { observerRef, containerRef };
};

export default useScrollObserver;
