import produce from 'immer';
import { CatalogueTab } from 'src/logic/models/catalogue/catalogue-content';
import Category from 'src/logic/models/catalogue/Category';
import create from 'zustand';

export interface CatalogueSlice {
  catalogueProperties: {
    // General
    searchValue: string;
    selectedTab: CatalogueTab;
    isCatalogueSearchPopupOpen: boolean;

    // Products & Services Tab
    selectedGroupId: number | null;
    selectedCategoryId: number | null;
    selectedClassId: number | null;
    selectedType: Category | null;
  };

  catalogueActions: {
    setSearchValue: (newValue: string) => void;
    setSelectedTab: (newTab: CatalogueTab) => void;

    openCatalogueSearchPopup: () => void;
    closeCatalogueSearchPopup: () => void;

    setSelectedGroupId: (groupId: number | null) => void;
    setSelectedCategoryId: (categoryId: number | null) => void;
    setSelectedClassId: (classId: number | null) => void;
    setSelectedType: (type: Category | null) => void;
  };
}

export const useCatalogueSlice = create<CatalogueSlice>((set, get) => ({
  catalogueProperties: {
    searchValue: '',
    selectedTab: 'ALL',
    isCatalogueSearchPopupOpen: false,

    selectedGroupId: null,
    selectedCategoryId: null,
    selectedClassId: null,
    selectedType: null,
  },

  catalogueActions: {
    setSearchValue(newValue) {
      set(
        produce<CatalogueSlice>(draftState => {
          draftState.catalogueProperties.searchValue = newValue;
        })
      );
    },
    setSelectedTab(newTab) {
      set(
        produce<CatalogueSlice>(draftState => {
          draftState.catalogueProperties.selectedTab = newTab;
        })
      );
    },

    openCatalogueSearchPopup: () => {
      set(
        produce<CatalogueSlice>(draftState => {
          draftState.catalogueProperties.isCatalogueSearchPopupOpen = true;
        })
      )
    },
    closeCatalogueSearchPopup: () => {
      set(
        produce<CatalogueSlice>(draftState => {
          draftState.catalogueProperties.isCatalogueSearchPopupOpen = false;
          draftState.catalogueProperties.selectedTab = 'ALL';
          draftState.catalogueProperties.searchValue = '';
        })
      )
    },


    setSelectedGroupId(groupId) {
      if (get().catalogueProperties.selectedGroupId === groupId) return;
      set(
        produce<CatalogueSlice>(draftState => {
          draftState.catalogueProperties.selectedGroupId = groupId;
          draftState.catalogueProperties.selectedCategoryId = null;
          draftState.catalogueProperties.selectedClassId = null;
          draftState.catalogueProperties.selectedType = null;
        })
      );
    },
    setSelectedCategoryId(categoryId) {
      if (get().catalogueProperties.selectedCategoryId === categoryId) return;
      set(
        produce<CatalogueSlice>(draftState => {
          draftState.catalogueProperties.selectedCategoryId = categoryId;
          draftState.catalogueProperties.selectedClassId = null;
          draftState.catalogueProperties.selectedType = null;
        })
      );
    },
    setSelectedClassId(classId) {
      if (get().catalogueProperties.selectedClassId === classId) return;
      set(
        produce<CatalogueSlice>(draftState => {
          draftState.catalogueProperties.selectedClassId = classId;
          draftState.catalogueProperties.selectedType = null;
        })
      );
    },
    setSelectedType(type) {
      if (get().catalogueProperties.selectedType?.id === type?.id) return;
      set(
        produce<CatalogueSlice>(draftState => {
          draftState.catalogueProperties.selectedType = type;
        })
      );
    },
  },
}));
