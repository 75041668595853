import { Grid, Typography } from '@mui/material';
import Link from 'next/link';
import { CatalogueBrand } from 'src/logic/models/catalogue/catalogue-content';
import { sm } from 'src/site-map';
import { catalogueBrandItem } from '../../index-page/styles';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';

type IProps = { brand: CatalogueBrand };

const sliceDescription = (description: string, MAX_LENGTH: number = 100) => {
  if (!description) return '';
  let sliced = description?.slice(0, MAX_LENGTH);
  if (description.length > MAX_LENGTH) {
    return `${sliced}...`;
  }
  return sliced;
};

const CatalogueFeaturedBrandCard = ({ brand }: IProps) => {
  const { isCatalogueSearchPopupOpen } = useCatalogueSlice(state => state.catalogueProperties);
  const { closeCatalogueSearchPopup } = useCatalogueSlice(state => state.catalogueActions);
  return (
    <Link href={sm.catalogue.brands.brandId(`${brand.id}`).url}>
      <Grid item sx={{ ...catalogueBrandItem, flexDirection: 'row', gap: '1rem' }}
        onClick={() => isCatalogueSearchPopupOpen && closeCatalogueSearchPopup()}
      >
        <img
          src={brand.cover_url}
          alt=''
          style={{ objectFit: 'cover', objectPosition: 'center', width: '82px', height: '82px', borderRadius: '10px' }}
        />

        <div>
          <img
            style={{ maxWidth: '117px', height: '24px', objectFit: 'contain' }}
            src={brand.image_url}
            alt={brand.name}
          />
          <Typography color='#C5C6CB' fontWeight={400} fontSize={13} lineHeight={1.2}>
            {sliceDescription(brand.description)}
          </Typography>
        </div>
      </Grid>
    </Link>
  );
};

export default CatalogueFeaturedBrandCard;
