import { request } from '@/helpers';
import { EnvironmentFilters } from 'src/logic/models/catalogue/AdvancedFilters';
import CatalogueIndex from 'src/logic/models/catalogue/CatalogueIndex';

export const getCatalogueIndex = (envFilters: EnvironmentFilters): Promise<{ data: CatalogueIndex }> => {
  return request({
    url: `/product/catalog`,
    method: 'GET',
    params: envFilters,
  });
};

export const getTypeProducts = (
  type_id: number
): Promise<{
  data: any;
}> => {
  return request({
    url: `/getProducts`,
    method: 'POST',
    data: {
      type_id,
    },
  });
};

export const getAllTabData = ({
  search,
  environmentFilters = {},
}: {
  search?: string;
  environmentFilters?: EnvironmentFilters;
}) => {
  return request({
    url: `/product/catalog/all_tab`,
    method: 'GET',
    params: {
      query: search,
      ...environmentFilters,
    },
  });
};
