import { CircularProgress, Typography } from '@mui/material';
import React from 'react';

type IProps = {
  label: string;
  minHeight?: React.CSSProperties['minHeight'];
  loadingIndicatorSize?: number | string;
};

const LoadingSection = ({ label, minHeight = '175px', loadingIndicatorSize = '2rem' }: IProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', minHeight }}>
      <CircularProgress size={loadingIndicatorSize} />
      <Typography color='#8B8D98' fontWeight={400} fontSize={13}>
        {label}
      </Typography>
    </div>
  );
};

export default LoadingSection;
