import { statusesTableData, UserType } from '@/config';
import { NotifyError } from '@/services';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Backdrop, Box, Button, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import useLoadingState from 'src/logic/hooks/use-loading-state';
import { useUserData } from 'src/logic/zustand/user';
import { sm } from 'src/site-map';
import { RoundedImageWithText, StyledPopper } from 'src/ui/components/shared';

export const UserNavMenu: React.VFC<{ imgURL: string }> = ({ imgURL, userData }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const initializeUser = useUserData(state => state.initializeUser);
  const logUserOut = useUserData(state => state.logUserOut);
  const companyInfo = useUserData(state => state.companyInfo);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const queryClient = useQueryClient();
  const clearCach = () => queryClient.removeQueries();

  const logoutLoading = useLoadingState();
  const handleLogout = () => {
    logoutLoading.startLoading();
    logUserOut({
      onSuccess: () => {
        clearCach();
        // router.push(sm.login.url);
        window.location.replace(sm.login.url);
      },
      onError: () => {
        NotifyError('Failed to Logout');
        logoutLoading.finishLoading();
      },
    });
  };
  const openAccountManagement = () => {
    router.push(sm.account.index.url);
    setAnchorEl(null);
  };
  const openCompanyUsers = () => {
    companyInfo.companyType == UserType.BUYER
      ? router.push(sm.portals.buyer.users.url)
      : router.push(sm.portals.seller.users.url);
    setAnchorEl(null);
  };
  const buttonStyle = { fontWeight: 400, display: 'flex', justifyContent: 'start' };
  const textButtonStyle = { mb: 1 };
  const userTypeConfig = statusesTableData[userData?.account_status];

  return (
    <>
      <Backdrop sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={logoutLoading.isLoading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-around',
            '&:hover': { cursor: 'pointer' },
            bgcolor: open ? 'info.contrastText' : 'inherit',
            width: '42px',
            borderRadius: '4px',
            height: '40px',
          }}
        >
          <Box sx={{ height: 32, width: 32, overflow: 'hidden', borderRadius: '50%' }}>
            <img src={imgURL} alt='profile image' width='100%' height='100%' />
          </Box>
          {/* <ExpandMoreIcon sx={{ fontSize: 18 }} /> */}
        </Box>
        <StyledPopper open={open} anchorEl={anchorEl} handleClose={handleClose}>
          <Grid sx={{ mt: 2, width: 210, height: 350, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Grid
              sx={{
                height: '100%',
                width: '90%',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'space-around',
              }}
            >
              <RoundedImageWithText
                title={userData.first_name + ' ' + userData.last_name}
                subtitle={userData.company_name}
                chipContent={t('account_status.' + userTypeConfig.text)}
                chipColor={userTypeConfig.color}
                chipTextColor={userTypeConfig.textColor}
                profilePictureUrl={imgURL}
                isSmallScreen={true}
                editImage={false}
              />
              <Divider />
              <Box>
                <Button variant='text' fullWidth onClick={openAccountManagement} sx={buttonStyle}>
                  <Typography sx={textButtonStyle}>{t('pages_names.account_management')}</Typography>
                </Button>
                <Button variant='text' fullWidth sx={buttonStyle} onClick={openCompanyUsers}>
                  <Typography sx={textButtonStyle}>{t('pages_names.users')}</Typography>
                </Button>
                <Button variant='text' fullWidth sx={buttonStyle} color='error' onClick={handleLogout}>
                  <Typography sx={textButtonStyle}>{t('texts.log_out')}</Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </StyledPopper>
      </Box>
    </>
  );
};
