import { CatalogueBrand } from 'src/logic/models/catalogue/catalogue-content';
import { Swiper, SwiperSlide } from 'swiper/react';
import CatalogueBrandCard from '../../components/CatalogueBrandCard';
import { commonSwiperConfigs } from '../../utils/swiper.config';

const SwiperBrands = ({ brands }: { brands: CatalogueBrand[] }) => {
  return (
    <Swiper {...commonSwiperConfigs}>
      {brands?.map((brand, index) => (
        <SwiperSlide key={brand.id || index} style={{ width: 'auto' }}>
          <div style={{ width: '125px' }}>
            <CatalogueBrandCard brand={brand} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperBrands;
