import { CatalogueSeller } from 'src/logic/models/catalogue/catalogue-content';
import { Swiper, SwiperSlide } from 'swiper/react';
import CatalogueFeaturedSellerCard from '../../components/CatalogueFeaturedSellerCard';
import { commonSwiperConfigs } from '../../utils/swiper.config';

const SwiperSellers = ({ sellers }: { sellers: CatalogueSeller[] | null }) => {
  return (
    <Swiper {...commonSwiperConfigs}>
      {sellers?.map((seller, index) => (
        <SwiperSlide key={seller.seller.id || index} style={{ width: 'auto' }}>
          <CatalogueFeaturedSellerCard seller={seller} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperSellers;
