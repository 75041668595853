import React from 'react';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import ErrorSection from '../../../components/ErrorSection';
import LoadingSection from '../../../components/LoadingSection';
import { useClasses } from '../../../queries/classifications';
import GeneralCategoryList from './GeneralCategoryList';

const ClassesSection = () => {
  const { data, isLoading, isError, refetch, hasNextPage, isFetchingNextPage, fetchNextPage } = useClasses();
  const { setSelectedClassId } = useCatalogueSlice(state => state.catalogueActions);
  const { selectedClassId } = useCatalogueSlice(state => state.catalogueProperties);

  const classes = React.useMemo(() => {
    return data?.pages?.flatMap(page => page.data) ?? [];
  }, [data]);

  if (isLoading) {
    return <LoadingSection label='Loading Classes' />;
  }
  if (isError) {
    return <ErrorSection refetch={refetch} />;
  }

  if (!data) return null;
  return (
    <GeneralCategoryList
      title='Class'
      items={classes}
      selectedItemId={selectedClassId}
      onSelect={classItem => setSelectedClassId(classItem.id)}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default ClassesSection;
