import { useDrawerStore, useProcurementStore } from '@/zustand';
import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import React, { FC } from 'react';
import { sm } from 'src/site-map';
import { SummaryContent } from '../form-summary-dialog';

interface props {
  canViewCatalog?: boolean;
}

export const FormProductManagerHeader: FC<props> = ({ canViewCatalog }) => {
  const { push, events } = useRouter();
  const { data, actions } = useProcurementStore(state => state.productFormManagerProperties);
  const isAnyProductSaving = data.loadingProductsIds.length > 0;

  // const [shouldShowLeaveConfirmDialog, setShouldShowLeaveConfirmDialog] =
  //   React.useState(false);
  // const [nextRouterPath, setNextRouterPath] = React.useState<string>();
  const removeListener = () => {
    events.off('routeChangeStart', onRouteChangeStart);
  };
  // const handleSaveAction = (e: any | null, handleSaveSucess?: () => void) =>
  //   actions.save({
  //     onSuccess: handleSaveSucess
  //   });

  //prevent browser from reload or closing the browser in case there are unsaved changes
  React.useEffect(() => {
    const handleBeforeUnload = event => {
      if (isAnyProductSaving) {
        event.preventDefault();
        event.returnValue = ''; // Required for Chrome
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isAnyProductSaving]);

  const onRouteChangeStart = React.useCallback(
    (nextPath: string) => {
      if (nextPath.startsWith('/catalogue')) {
        return;
      }

      if (isAnyProductSaving) {
        window.alert('Still saving...');
        throw 'cancelRouteChange';
      }

      actions.reset();
      // setShouldShowLeaveConfirmDialog(true);
      // setNextRouterPath(nextPath);
    },
    [isAnyProductSaving]
  );

  // const onDiscardRouteChange = () => {
  //   actions.reset();
  //   setShouldShowLeaveConfirmDialog(false);
  //   removeListener();
  //   push(nextRouterPath);
  // };

  // const onCloseRouteChange = () => {
  //   setShouldShowLeaveConfirmDialog(false);
  //   setNextRouterPath(null);
  // };

  // const onConfirmRouteChange = () => {
  //   const handleSaveSucess = () => push(nextRouterPath);
  //   handleSaveAction(null, handleSaveSucess);
  //   setShouldShowLeaveConfirmDialog(false);
  //   removeListener();
  // };

  React.useEffect(() => {
    events.on('routeChangeStart', onRouteChangeStart);
    return removeListener;
  }, [onRouteChangeStart]);

  const redirectToProducts = () => push(sm.catalogue.index.url);
  // const isPurchaseOrderEnv: boolean =
  //   data.environment?.type === ProductManagerEnv.purchase_order;
  // const isRequisitionEnv: boolean =
  //   data.environment?.type === ProductManagerEnv.requisition;
  // const isCatalogEnv: boolean =
  //   data.environment?.type === ProductManagerEnv.catalog;
  // const isQuoteEnv: boolean =
  //   data.environment?.type === ProductManagerEnv.quote;

  return (
    <>
      {data.environment && (
        <Box
          sx={{
            bgcolor: '#fff',
            // height: 'calc(100vh - 65px)',
            // top: '65px',
            top: 0,
            right: 0,
            width: '350px',
            position: 'fixed',
            zIndex: 2,
          }}
        >
          {/* {isPurchaseOrderEnv && (
              <PurchaseOrderHeader
                redirectToProducts={redirectToProducts}
                handleSaveAction={handleSaveAction}
                openSummaryDialog={openSummaryDialog}
                canViewCatalog={canViewCatalog}
              />
            )}
            {isRequisitionEnv && (
              <RequisitionHeader
                redirectToProducts={redirectToProducts}
                handleSaveAction={handleSaveAction}
                openSummaryDialog={openSummaryDialog}
                canViewCatalog={canViewCatalog}
              />
            )}
            {isCatalogEnv && (
              <CatalogHeader
                redirectToProducts={redirectToProducts}
                handleSaveAction={handleSaveAction}
                openSummaryDialog={openSummaryDialog}
                canViewCatalog={canViewCatalog}
              />
            )}
            {isQuoteEnv && (
              <QuoteHeader
                redirectToProducts={redirectToProducts}
                handleSaveAction={handleSaveAction}
                openSummaryDialog={openSummaryDialog}
                canViewCatalog={canViewCatalog}
              />
            )} */}
          {/* <WarningPopup
            cancelButtonTitle={t('buttons.discard')}
            confirmButtonColor={'success'}
            confirmButtonTitle={t('buttons.save')}
            handleClose={onCloseRouteChange}
            handleConfirm={onConfirmRouteChange}
            handleCancel={onDiscardRouteChange}
            title={t('popups.subTitles.discard_saved_changes')}
            open={shouldShowLeaveConfirmDialog}
          /> */}
          <SummaryContent redirectToProductPage={redirectToProducts} canEditForm={true} />
        </Box>
      )}
    </>
  );
};
