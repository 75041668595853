import { Typography } from '@mui/material';
import React from 'react';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import { Swiper, SwiperSlide } from 'swiper/react';
import ErrorSection from '../../../components/ErrorSection';
import LoadingSection from '../../../components/LoadingSection';
import { useGroups } from '../../../queries/classifications';
import { commonSwiperConfigs } from '../../../utils/swiper.config';
import GroupCard from '../cards/GroupCard';

const GroupsSection = () => {
  const { data, isLoading, isError, refetch } = useGroups();
  const { setSelectedGroupId } = useCatalogueSlice(state => state.catalogueActions);
  const { selectedGroupId } = useCatalogueSlice(state => state.catalogueProperties);

  const groupItemsInPairs = React.useMemo(() => {
    if (!data) return [];

    const result = [];
    for (let i = 0; i < data.length; i += 2) {
      result.push(data.slice(i, i + 2));
    }
    return result;
  }, [data]);

  if (isLoading) {
    return <LoadingSection label='Loading Groups' />;
  }
  if (isError) {
    return <ErrorSection refetch={refetch} />;
  }

  if (!data) return null;
  return (
    <div>
      <Typography color='#3A3E50' fontSize={21} fontWeight={700} mb={1} ml={1}>
        Groups
      </Typography>
      <Swiper {...commonSwiperConfigs}>
        {groupItemsInPairs.map((pair, index) => (
          <SwiperSlide key={`${pair?.[0]?.id}-${pair?.[1]?.id}-${index}`} style={{ width: 'fit-content' }}>
            <div style={{ display: 'grid', gap: '1rem' }}>
              {pair.map(item => (
                <GroupCard
                  key={item.id}
                  group={item}
                  isSelected={item.id === selectedGroupId}
                  onClick={() => setSelectedGroupId(item.id)}
                />
              ))}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default GroupsSection;
