import { Product } from '@/models';
import { Button, Typography } from '@mui/material';
import Link from 'next/link';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import { sm } from 'src/site-map';
import { Swiper, SwiperSlide } from 'swiper/react';
import CatalogueProductCard from '../../components/CatalogueProductCard';
import EmptySection from '../../components/EmptySection';
import ErrorSection from '../../components/ErrorSection';
import LoadingSection from '../../components/LoadingSection';
import { useTypeProductsAndServices } from '../../queries/products';
import { commonSwiperConfigs } from '../../utils/swiper.config';

const ProductsAndServicesForType = () => {
  const { selectedType } = useCatalogueSlice(state => state.catalogueProperties);
  const { data, isLoading, isError, isSuccess, refetch } = useTypeProductsAndServices();
  const { isCatalogueSearchPopupOpen } = useCatalogueSlice(state => state.catalogueProperties);
  const { closeCatalogueSearchPopup } = useCatalogueSlice(state => state.catalogueActions);
  if (!selectedType) return null;

  const isEmpty = isSuccess && data && data.length === 0;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          // justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          marginBottom: '1rem',
        }}
      >
        <Typography color='#3A3E50' fontSize={14} fontWeight={400}>
          &quot;{selectedType.name}&quot; <strong>Products & Services</strong>
        </Typography>
        {!isEmpty && (
          <Link href={sm.catalogue.products.typeId(`${selectedType.id}`).url}>
            <Button type='button' sx={{ color: '#0074FF' }}
              onClick={() => {
                if (isCatalogueSearchPopupOpen) closeCatalogueSearchPopup();
              }}
            >
              View All
            </Button>
          </Link>
        )}
      </div>
      {isError && <ErrorSection refetch={refetch} />}
      {isLoading && <LoadingSection label='Loading Products & Services' />}
      {isSuccess && (
        <>
          {!isEmpty ? (
            <Swiper {...commonSwiperConfigs}>
              {data?.map((product: Product) => (
                <SwiperSlide key={product.id} style={{ width: 'fit-content' }}>
                  <CatalogueProductCard product={product} />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <EmptySection />
          )}
        </>
      )}
    </div>
  );
};

export default ProductsAndServicesForType;
