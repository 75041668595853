import { Product, ProductCategoryKindLabel } from '@/models';
import { Button, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import { sm } from 'src/site-map';

type IProps = {
  product: Product;
};

const ProductSearchCard = ({ product }: IProps) => {
  const { push } = useRouter();
  const { isCatalogueSearchPopupOpen } = useCatalogueSlice(state => state.catalogueProperties);
  const { closeCatalogueSearchPopup } = useCatalogueSlice(state => state.catalogueActions);
  return (
    <Button
      sx={{
        height: '100%',
        maxHeight: '100%',
        justifyContent: 'start',
        width: '100%',
        padding: '.5rem',
        display: 'flex',
        alignItems: 'center',
        gap: '.5rem',
        borderRadius: '8px',
        cursor: 'pointer',

        '& em': {
          color: '#0074FF',
          fontStyle: 'normal',
        },
      }}
      onClick={() => isCatalogueSearchPopupOpen && closeCatalogueSearchPopup() && push(sm.catalogue.products.productId(`${product.id}`).url)}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          width: '40px',
          height: '40px',
          flexShrink: 0,
        }}
      >
        <img src={product.image_url || ''} alt='' style={{ width: '40px', height: '40px', objectFit: 'contain' }} />
      </div>

      <div>
        <Typography fontWeight={400} fontSize={13} color='#3A3E50' noWrap textAlign={'start'}>
          {product.search_metadata?._highlightResult ? (
            <span dangerouslySetInnerHTML={{ __html: product.search_metadata._highlightResult.name.value }}></span>
          ) : (
            product.name
          )}
        </Typography>

        <Typography color='#8B8D98' fontSize={12} fontWeight={400} textAlign={'start'}>
          {ProductCategoryKindLabel[product.category_kind]}
        </Typography>
      </div>
    </Button>
  );
};

export default ProductSearchCard;
