import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { CatalogueBrandsTabResponse, getBrandsItems, getBrandsTabContent } from 'src/logic/services/catalogue/brands';
import { commonOptions, useCommonKeyProps } from './common';

// ==================== Brands Tab ====================

export const useCatalogueBrandsTab = (
  search?: string,
  options: UseQueryOptions<CatalogueBrandsTabResponse, Error> | undefined = {}
): UseQueryResult<CatalogueBrandsTabResponse, Error> => {
  const keyProps = useCommonKeyProps();

  return useQuery<CatalogueBrandsTabResponse, Error>({
    queryKey: ['CATALOGUE_BRANDS_TAB', keyProps, search],
    queryFn: async () => {
      const { data } = await getBrandsTabContent({ search, environmentFilters: keyProps.environmentFilters });
      return data;
    },
    ...commonOptions,
    ...options,
  });
};

// ==================== Brands Items ====================

export const useCatalogueBrands = ({
  search,
  sortBy,
  options = {},
  initialData,
}: {
  search?: string;
  sortBy?: string;
  initialData?: CatalogueBrandsTabResponse;
  options?: UseInfiniteQueryOptions<CatalogueBrandsTabResponse, Error>;
}): UseInfiniteQueryResult<CatalogueBrandsTabResponse, Error> => {
  const keyProps = useCommonKeyProps();

  return useInfiniteQuery<CatalogueBrandsTabResponse, Error>({
    queryKey: ['CATALOGUE_BRANDS_ITEMS', keyProps, search, sortBy],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getBrandsItems({
        page: pageParam,
        search,
        sortBy,
        environmentFilters: keyProps.environmentFilters,
      });
      return data;
    },
    getNextPageParam: (lastPage: CatalogueBrandsTabResponse) => {
      return lastPage.data.meta.current_page < lastPage.data.meta.last_page
        ? lastPage.data.meta.current_page + 1
        : undefined;
    },
    initialData: initialData && !sortBy ? { pages: [initialData], pageParams: [1] } : undefined,
    ...commonOptions,
    ...options,
  });
};
