import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  CatalogueSellersTabResponse,
  getSellersItems,
  getSellersTabContent,
} from 'src/logic/services/catalogue/sellers';
import { commonOptions, useCommonKeyProps } from './common';

// ==================== Sellers Tab ====================

export const useCatalogueSellersTab = (
  search?: string,
  options: UseQueryOptions<CatalogueSellersTabResponse, Error> | undefined = {}
): UseQueryResult<CatalogueSellersTabResponse, Error> => {
  const keyProps = useCommonKeyProps();

  return useQuery<CatalogueSellersTabResponse, Error>({
    queryKey: ['CATALOGUE_SELLERS_TAB', keyProps, search],
    queryFn: async () => {
      const { data } = await getSellersTabContent({ search, environmentFilters: keyProps.environmentFilters });
      return data;
    },
    ...commonOptions,
    ...options,
  });
};

// ==================== Sellers Items ====================

export const useCatalogueSellers = ({
  search,
  sortBy,
  options = {},
  initialData,
}: {
  search?: string;
  sortBy?: string;
  initialData?: CatalogueSellersTabResponse;
  options?: UseInfiniteQueryOptions<CatalogueSellersTabResponse, Error>;
}): UseInfiniteQueryResult<CatalogueSellersTabResponse, Error> => {
  const keyProps = useCommonKeyProps();

  return useInfiniteQuery<CatalogueSellersTabResponse, Error>({
    queryKey: ['CATALOGUE_SELLERS_ITEMS', keyProps, search, sortBy],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getSellersItems({
        page: pageParam,
        search,
        sortBy,
        environmentFilters: keyProps.environmentFilters,
      });
      return data;
    },
    getNextPageParam: (lastPage: CatalogueSellersTabResponse) => {
      return lastPage.data.meta.current_page < lastPage.data.meta.last_page
        ? lastPage.data.meta.current_page + 1
        : undefined;
    },
    initialData: initialData && !sortBy ? { pages: [initialData], pageParams: [1] } : undefined,
    ...commonOptions,
    ...options,
  });
};
