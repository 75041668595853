import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Button, Typography } from '@mui/material';
import useScrollObserver from 'src/logic/hooks/use-scroll-observer';
import Category from 'src/logic/models/catalogue/Category';
import LoadingSection from '../../../components/LoadingSection';

type IProps = {
  title: string;
  items: Category[];
  selectedItemId: number | null | undefined;
  onSelect: (item: Category) => void;

  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  fetchNextPage?: Function;
};

const GeneralCategoryList = ({
  title,
  items,
  selectedItemId,
  onSelect,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: IProps) => {
  const { observerRef, containerRef } = useScrollObserver({
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage?.();
      }
    },
  });

  return (
    <div>
      <Typography color='#8B8D98' fontSize={13} fontWeight={400} mb={1} ml={1}>
        {title}
      </Typography>
      <div
        style={{ maxHeight: '200px', overflow: 'auto', paddingInlineEnd: '.5rem' }}
        className='styled-scrollbar'
        ref={containerRef}
      >
        {items?.map(item => (
          <Button
            key={item.id}
            sx={{
              justifyContent: 'space-between',
              color: '#3A3E50',
              textAlign: 'start',
              lineHeight: 1.2,
              maxHeight: '100% !important',
              height: '100% !important',
            }}
            style={item.id === selectedItemId ? { fontWeight: 700, backgroundColor: '#F6F8FC' } : {}}
            fullWidth
            type='button'
            variant={'text'}
            onClick={() => onSelect(item)}
            endIcon={<ChevronRightIcon style={{ color: '#E8E8EA' }} />}
          >
            {item.name}
          </Button>
        ))}

        {hasNextPage && (
          <div
            style={{ marginBlock: '.5rem', opacity: isFetchingNextPage ? 1 : 0, transition: 'opacity 200ms' }}
            ref={observerRef}
          >
            <LoadingSection minHeight='auto' label='Loading More...' loadingIndicatorSize='1rem' />
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneralCategoryList;
