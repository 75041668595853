import { Box, Button, Typography } from '@mui/material';
import { CatalogueBrand } from 'src/logic/models/catalogue/catalogue-content';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import SwiperBrands from '../../@shared/swiperBrands';

type BrandsSectionProps = {
  FeaturedBrands: CatalogueBrand[];
};

const BrandsSection = ({ FeaturedBrands }: BrandsSectionProps) => {
  const { setSelectedTab } = useCatalogueSlice(state => state.catalogueActions);

  return (
    <Box>
      <Box display='flex'
        // justifyContent='space-between' 
        alignItems='center'>
        <Typography color='#3A3E50' fontSize={21} fontWeight={700} mb={1} mx={1}>
          Featured Brands
        </Typography>
        <Button
          onClick={() => setSelectedTab('BRANDS')}
          variant='text'
          sx={{ fontSize: 13, fontWeight: 400, color: '#0074FF', pt: 0 }}
        >
          View All
        </Button>
      </Box>

      <SwiperBrands brands={FeaturedBrands} />
    </Box>
  );
};

export default BrandsSection;
