import { useUserData } from '@/zustand';
import { Typography } from '@mui/material';
import AddNewPrivateItemLink from '../../shared/Add-private-item-link';

const EmptySection = () => {
  const { isBuyer } = useUserData();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
        minHeight: '175px',
      }}
    >
      <img src='/assets/images/no-results.png' alt='' width={80} height={80} style={{ objectFit: 'contain' }} />
      <Typography color='#3A3E50' fontWeight={700} fontSize={21} textAlign={'center'}>
        No Results Found
      </Typography>
      {isBuyer() && <AddNewPrivateItemLink />}
    </div>
  );
};

export default EmptySection;
