import React from 'react';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import ErrorSection from '../../../components/ErrorSection';
import LoadingSection from '../../../components/LoadingSection';
import { useCategories } from '../../../queries/classifications';
import GeneralCategoryList from './GeneralCategoryList';

const CategoriesSection = () => {
  const { data, isLoading, isError, refetch, hasNextPage, isFetchingNextPage, fetchNextPage } = useCategories();
  const { setSelectedCategoryId } = useCatalogueSlice(state => state.catalogueActions);
  const { selectedCategoryId } = useCatalogueSlice(state => state.catalogueProperties);

  const categories = React.useMemo(() => {
    return data?.pages?.flatMap(page => page.data) ?? [];
  }, [data]);

  if (isLoading) {
    return <LoadingSection label='Loading Categories' />;
  }
  if (isError) {
    return <ErrorSection refetch={refetch} />;
  }

  if (!data) return null;
  return (
    <GeneralCategoryList
      title='Category'
      items={categories}
      selectedItemId={selectedCategoryId}
      onSelect={category => setSelectedCategoryId(category.id)}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default CategoriesSection;
