import { Box } from '@mui/material';
import ErrorSection from '../../components/ErrorSection';
import LoadingSection from '../../components/LoadingSection';
import { useCatalogueAllTabContent } from '../../queries/all';
import SuggestedProductsAndServicesSection from '../@shared/SuggestedProductsAndServicesSection';
import BrandsSection from './cards/brandsSection';
import SellersSection from './cards/sellersSection';

const AllTabContent = () => {
  const { data, isLoading, isError, refetch } = useCatalogueAllTabContent();

  if (isLoading) {
    return <LoadingSection label='Loading' />;
  }
  if (isError) {
    return <ErrorSection refetch={refetch} />;
  }

  if (!data) return null;
  return (
    <Box>
      <BrandsSection FeaturedBrands={data.FeaturedBrands} />
      {data.FeaturedSellers && data.FeaturedSellers.length > 0 && (
        <SellersSection FeaturedSellers={data.FeaturedSellers} />
      )}
      <SuggestedProductsAndServicesSection />
    </Box>
  );
};

export default AllTabContent;
