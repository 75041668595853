import { useDebounce } from '@/hooks';
import SearchIcon from '@mui/icons-material/Search';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import { AiFilledIcon } from 'src/ui/icons/ai-filled';
import ClearIcon from '@mui/icons-material/Clear';

const SearchField = () => {
  // Search
  const { searchValue } = useCatalogueSlice(state => state.catalogueProperties);
  const { setSearchValue } = useCatalogueSlice(state => state.catalogueActions);
  const [liveSearchValue, setLiveSearchValue] = React.useState<string>(searchValue);
  useDebounce(() => setSearchValue(liveSearchValue), 700, [liveSearchValue]);

  const [isFocused, setIsFocued] = React.useState<boolean>(false);
  const handleClear = () => {
    setLiveSearchValue('');
  };

  return (
    <div
      style={{
        padding: '8px',
        borderRadius: '40px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: isFocused ? '#3A3E50' : '#E2E8F0',
        display: 'flex',
        gap: '1.5rem',
        alignItems: 'center',
        transition: 'border-color 200ms',
      }}
    >
      <div style={{ flexGrow: 1 }}>
        <TextField
          id='catalogue-search'
          variant='outlined'
          placeholder='Search for products, services, brands and sellers'
          value={liveSearchValue}
          onChange={e => setLiveSearchValue(e.target.value)}
          sx={{
            backgroundColor: "#F4F7FE",
            width: "100%",
            borderRadius: "40px",
            "& fieldset": {
              borderRadius: "40px",
              border: "none",
            },
            "& input": {
              padding: "8px 12px",
            },
            "& input:-webkit-autofill": {
              WebkitBoxShadow: "0 0 0 1000px #F4F7FE inset",
              WebkitTextFillColor: "black",
              transition: "background-color 5000s ease-in-out 0s",
            },
          }}
          inputProps={{
            style: {
              borderRadius: "40px",
              padding: "8px 12px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon sx={{ fontSize: 14 }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                {searchValue && (
                  <IconButton onClick={handleClear} size='small' edge='end'>
                    <ClearIcon fontSize='small' />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          onFocus={() => setIsFocued(true)}
          onBlur={() => setIsFocued(false)}
        />
      </div>

      <Button type='button' sx={{ fontWeight: 500, color: '#0074FF', fontSize: 14 }}>
        <AiFilledIcon fontSize={'inherit'} /> <span style={{ paddingInlineStart: '.5rem' }}>Ask AI</span>
      </Button>
    </div>
  );
};

export default SearchField;
