import { ProductCategoryKind } from '@/models';
import { Typography } from '@mui/material';
import { useRouter } from 'next/router';
import useScrollObserver from 'src/logic/hooks/use-scroll-observer';
import Category from 'src/logic/models/catalogue/Category';
import { sm } from 'src/site-map';
import CategoryCardWithIcon from '../../../components/CategoryCardWithIcon';
import LoadingSection from '../../../components/LoadingSection';

type IProps = {
  title: string;
  items: Category[];
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  fetchNextPage: Function;
};

const SearchGeneralCategoryList = ({ title, items, hasNextPage, isFetchingNextPage, fetchNextPage }: IProps) => {
  const { push } = useRouter();

  const handleClick = (item: any, category_kind: number) => {
    const query: { [key: string]: any } = { 'category-kind': category_kind };
    const titleKey = `${title.toLowerCase()}-id`;
    query[titleKey] = item;
    push({
      pathname: sm.catalogue.searchResults.url,
      query,
    });
  };

  const { observerRef, containerRef } = useScrollObserver({
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage?.();
      }
    },
  });

  const isEmpty = items.length === 0;

  return (
    <div>
      <Typography color='#8B8D98' fontSize={13} fontWeight={400} mb={1} ml={1}>
        {title}
      </Typography>
      {isEmpty ? (
        <Typography color='#3A3E50' fontSize={17} fontWeight={400} ml={1}>
          No results found
        </Typography>
      ) : (
        <div
          style={{ maxHeight: '250px', overflow: 'auto', paddingInlineEnd: '.5rem' }}
          className='styled-scrollbar'
          ref={containerRef}
        >
          {items?.map(item => (
            <CategoryCardWithIcon
              key={item.id}
              category={item}
              variant='outlined'
              maxTitleWidth='220px'
              cursor='default'
              onNumberOfProductsClick={() => handleClick(item.id, ProductCategoryKind.PRODUCT)}
              onNumberOfServicesClick={() => handleClick(item.id, ProductCategoryKind.SERVICE)}
              withFullPath
            />
          ))}

          {hasNextPage && (
            <div
              style={{ marginBlock: '.5rem', opacity: isFetchingNextPage ? 1 : 0, transition: 'opacity 200ms' }}
              ref={observerRef}
            >
              <LoadingSection minHeight='auto' label='Loading More...' loadingIndicatorSize='1rem' />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchGeneralCategoryList;
