import React from 'react';
import useScrollObserver from 'src/logic/hooks/use-scroll-observer';
import { CatalogueSellersTabResponse } from 'src/logic/services/catalogue/sellers';
import CatalogueSellerCard from '../../components/CatalogueSellerCard';
import LoadingSection from '../../components/LoadingSection';
import { useCatalogueSellers } from '../../queries/sellers';

type IProps = {
  initialData: CatalogueSellersTabResponse;
  sortBy: string;
  search?: string;
};

const SellersContent = ({ initialData, sortBy, search }: IProps) => {
  const { data, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } = useCatalogueSellers({
    initialData,
    search,
    sortBy,
  });

  const { observerRef, containerRef } = useScrollObserver({
    onIntersect: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
  });

  const sellers = React.useMemo(() => {
    return data?.pages?.flatMap(page => page.data.data) ?? [];
  }, [data]);

  if (isLoading) {
    return <LoadingSection label='Loading Sellers...' />;
  }

  return (
    <div
      style={{ maxHeight: '400px', overflow: 'auto', paddingInlineEnd: '.5rem' }}
      className='styled-scrollbar'
      ref={containerRef}
    >
      <div
        style={{
          marginBlock: '1rem',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: '1rem',
        }}
      >
        {sellers.map(seller => (
          <CatalogueSellerCard key={seller.seller.id} seller={seller} />
        ))}
      </div>

      {hasNextPage && (
        <div
          style={{ marginBlock: '.5rem', opacity: isFetchingNextPage ? 1 : 0, transition: 'opacity 200ms' }}
          ref={observerRef}
        >
          <LoadingSection minHeight='auto' label='Loading Sellers...' loadingIndicatorSize='1rem' />
        </div>
      )}
    </div>
  );
};

export default SellersContent;
