import React from 'react';
import Category from 'src/logic/models/catalogue/Category';
import { PaginatedResponse } from 'src/logic/models/queries';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import { useSearchedCategories } from '../../../queries/search';
import SearchGeneralCategoryList from './SearchGeneralCategoryList';

type IProps = {
  categories: PaginatedResponse<Category>;
};

const CategoriesSearchResults = ({ categories }: IProps) => {
  const { searchValue } = useCatalogueSlice(state => state.catalogueProperties);
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isError } = useSearchedCategories({
    initialData: categories,
    search: searchValue,
  });

  const categoriesItems = React.useMemo(() => {
    return data?.pages?.flatMap(page => page.data) ?? [];
  }, [data]);

  return (
    <SearchGeneralCategoryList
      title={'Category'}
      items={categoriesItems}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={() => {
        if (!isError) fetchNextPage();
      }}
    />
  );
};

export default CategoriesSearchResults;
