import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SortConfig } from 'src/logic/models/@shared/sort';

type IProps = {
  sortConfig: SortConfig;
  onSortItemSelected: (sortItemId: string) => void;
};

const SortSelect = ({ onSortItemSelected, sortConfig }: IProps) => {
  const { t } = useTranslation();
  const selectRef = React.useRef<HTMLDivElement>(null);

  const handleIconClick = () => {
    if (selectRef.current) {
      const event = new Event('mousedown', { bubbles: true });
      selectRef.current.dispatchEvent(event);
    }
  };

  //   const defaultValue = sortData.some(sortItem => sortItem.selected) ? undefined : sortData[0].id;

  return (
    <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id='demo-simple-select-standard-label'>
        <Typography variant='body2' color='info.light' fontWeight='400'>
          {t('texts.sort_by')}
        </Typography>
      </InputLabel>
      <Select
        labelId='demo-simple-select-standard-label'
        id='demo-simple-select-standard'
        defaultValue={'DEFAULT'}
        onChange={event => {
          onSortItemSelected(event.target.value);
        }}
        sx={{
          '& .MuiSelect-select': {
            backgroundColor: 'transparent !important',
            fontSize: '14px !important',
            fontWeight: 500,
            color: '#3A3E50 !important',
          },
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
        disableUnderline
        IconComponent={props => (
          <ExpandMoreIcon
            {...props}
            onClick={() => {
              handleIconClick();
            }}
            sx={{ fontSize: '20px !important', color: '#3A3E50 !important', cursor: 'pointer' }}
          />
        )}
        ref={selectRef}
      >
        {sortConfig.map(sortItem => (
          <MenuItem key={sortItem.id} value={sortItem.id}>
            {sortItem.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SortSelect;
