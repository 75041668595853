import { Grid, Typography } from '@mui/material';
import Link from 'next/link';
import { CatalogueBrand } from 'src/logic/models/catalogue/catalogue-content';
import { formatInteger } from 'src/logic/utils/numbers';
import { sm } from 'src/site-map';
import { catalogueBrandItem } from '../../index-page/styles';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';

type IProps = { brand: CatalogueBrand };

const CatalogueBrandCard = ({ brand }: IProps) => {
  const { isCatalogueSearchPopupOpen } = useCatalogueSlice(state => state.catalogueProperties);
  const { closeCatalogueSearchPopup } = useCatalogueSlice(state => state.catalogueActions);
  return (
    <Link href={sm.catalogue.brands.brandId(`${brand.id}`).url}>
      <Grid item sx={catalogueBrandItem} onClick={() => isCatalogueSearchPopupOpen && closeCatalogueSearchPopup()}>
        <img
          style={{ maxWidth: '53px', height: '50px', objectFit: 'contain', marginBottom: '.5rem' }}
          src={brand.image_url}
          alt={brand.name}
        />
        <Typography className='typography-bold' sx={{ fontSize: '13px', fontWeight: 400, color: 'text.disabled' }}>
          {brand.search_metadata?._highlightResult ? (
            <span dangerouslySetInnerHTML={{ __html: brand.search_metadata._highlightResult.name.value }}></span>
          ) : (
            brand.name
          )}
        </Typography>
        {brand.number_of_products !== undefined && (
          <Typography sx={{ fontSize: '11px', fontWeight: 400, color: 'text.third' }}>
            {formatInteger(brand.number_of_products)} products
          </Typography>
        )}
      </Grid>
    </Link>
  );
};

export default CatalogueBrandCard;
