import { Close } from '@mui/icons-material';
import { Dialog, IconButton } from '@mui/material';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import CatalogueContent from 'src/ui/components/smart/catalogue/catalogue-content/CatalogueContent';

const CatalogueSearchPopup = () => {
    const { isCatalogueSearchPopupOpen } = useCatalogueSlice((state) => state.catalogueProperties);
    const { closeCatalogueSearchPopup } = useCatalogueSlice((state) => state.catalogueActions);

    return (
        <Dialog
            open={isCatalogueSearchPopupOpen}
            onClose={closeCatalogueSearchPopup}
            fullWidth
            maxWidth="lg"
            PaperProps={{
                sx: {
                    width: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                    px: 2
                }
            }}
        // disableScrollLock
        >
            <IconButton
                edge="end"
                color="inherit"
                onClick={closeCatalogueSearchPopup}
                disableRipple
                sx={{
                    position: 'absolute',
                    top: 25,
                    right: 15,
                    color: 'text.secondary',
                }}
            >
                <Close sx={{ fontSize: '18px' }} />
            </IconButton>

            <div
                style={{
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                }}
            >
                <CatalogueContent />
            </div>
        </Dialog>
    );
};

export default CatalogueSearchPopup;
