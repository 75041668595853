import React from 'react';
import Category from 'src/logic/models/catalogue/Category';
import { PaginatedResponse } from 'src/logic/models/queries';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import { useSearchedClasses } from '../../../queries/search';
import SearchGeneralCategoryList from './SearchGeneralCategoryList';

type IProps = {
  classes: PaginatedResponse<Category>;
};

const ClassesSearchResults = ({ classes }: IProps) => {
  const { searchValue } = useCatalogueSlice(state => state.catalogueProperties);
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isError } = useSearchedClasses({
    initialData: classes,
    search: searchValue,
  });

  const classesItems = React.useMemo(() => {
    return data?.pages?.flatMap(page => page.data) ?? [];
  }, [data]);

  return (
    <SearchGeneralCategoryList
      title={'Class'}
      items={classesItems}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={() => {
        if (!isError) {
          fetchNextPage();
        }
      }}
    />
  );
};

export default ClassesSearchResults;
