import { Box, Button, Typography } from '@mui/material';
import { CatalogueSeller } from 'src/logic/models/catalogue/catalogue-content';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import SectionDivider from '../../../components/SectionDivider';
import SwiperSellers from '../../@shared/swiperSellers';

type SellersSectionProps = {
  FeaturedSellers: CatalogueSeller[] | null;
};

const SellersSection = ({ FeaturedSellers }: SellersSectionProps) => {
  const { setSelectedTab } = useCatalogueSlice(state => state.catalogueActions);

  return (
    <div data-testid='featured-sellers'>
      <SectionDivider />

      <Box>
        <Box display='flex'
          // justifyContent='space-between'
          alignItems='center'>
          <Typography data-test-id='featured-sellers' color='#3A3E50' fontSize={21} fontWeight={700} mb={1} mx={1}>
            Featured Sellers
          </Typography>
          <Button
            onClick={() => setSelectedTab('SELLERS')}
            variant='text'
            sx={{ fontSize: 13, fontWeight: 400, color: '#0074FF', pt: 0 }}
          >
            View All
          </Button>
        </Box>

        <SwiperSellers sellers={FeaturedSellers} />
      </Box>
    </div>
  );
};

export default SellersSection;
