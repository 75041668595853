import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { Typography } from '@mui/material';
import React from 'react';
import SortSelect from 'src/ui/components/dumb/shared/sort-select';
import CatalogueFeaturedBrandCard from '../../components/CatalogueFeaturedBrandCard';
import ErrorSection from '../../components/ErrorSection';
import LoadingSection from '../../components/LoadingSection';
import SectionDivider from '../../components/SectionDivider';
import { useCatalogueBrandsTab } from '../../queries/brands';
import BrandsContent from './BrandsContent';

const BrandsTabContent = () => {
  const [sortBy, setSortBy] = React.useState<string>('');
  const { data, isLoading, isError, refetch } = useCatalogueBrandsTab();

  if (isLoading) {
    return <LoadingSection label='Loading Brands' />;
  }
  if (isError) {
    return <ErrorSection refetch={refetch} />;
  }

  if (!data) return null;
  return (
    <div>
      {data?.featured_brands && data.featured_brands.length > 0 && (
        <>
          <Typography color='#3A3E50' fontSize={21} fontWeight={700} ml={1}>
            Featured Brands
          </Typography>

          <div
            style={{
              marginBlock: '1rem',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))',
              gap: '1rem',
            }}
          >
            {data.featured_brands.map(brand => (
              <CatalogueFeaturedBrandCard key={brand.id} brand={brand} />
            ))}
          </div>

          <SectionDivider />
        </>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography color='#3A3E50' fontSize={13} fontWeight={500} ml={1}>
          <LocalOfferOutlinedIcon sx={{ fontSize: '16px', transform: 'translateY(3px)' }} /> All Brands
        </Typography>
        <SortSelect sortConfig={data.sort_data} onSortItemSelected={setSortBy} />
      </div>

      <BrandsContent initialData={data} sortBy={sortBy} />
    </div>
  );
};

export default BrandsTabContent;
