import React from 'react';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';

const MIN_SEARCH_CHARACTERS = 3;

export const iseSearchState = (searchValue: string): boolean => {
  return !!searchValue && searchValue.length >= MIN_SEARCH_CHARACTERS;
};

export const useIsSearchState = (): boolean => {
  const { searchValue } = useCatalogueSlice(state => state.catalogueProperties);
  return React.useMemo(() => iseSearchState(searchValue), [searchValue]);
};
