import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import Category from 'src/logic/models/catalogue/Category';
import { PaginatedResponse } from 'src/logic/models/queries';
import { getChildrenCategories, getGroups } from 'src/logic/services/catalogue/classification';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import { commonOptions, useCommonKeyProps } from './common';

const getNextPageParam = (lastPage: PaginatedResponse<Category>) => {
  return lastPage.meta.current_page < lastPage.meta.last_page ? lastPage.meta.current_page + 1 : undefined;
};

export const useGroups = (
  options: UseQueryOptions<Category[], Error> | undefined = {}
): UseQueryResult<Category[], Error> => {
  const keyProps = useCommonKeyProps();

  return useQuery<Category[], Error>({
    queryKey: ['CATALOGUE_GROUPS', keyProps],
    queryFn: async () => {
      const { data } = await getGroups({ environmentFilters: keyProps.environmentFilters });
      return data.items;
    },
    ...commonOptions,
    ...options,
  });
};

export const useCategories = (
  options: UseInfiniteQueryOptions<PaginatedResponse<Category>, Error> | undefined = {}
): UseInfiniteQueryResult<PaginatedResponse<Category>, Error> => {
  const { selectedGroupId } = useCatalogueSlice(state => state.catalogueProperties);
  const keyProps = useCommonKeyProps();

  return useInfiniteQuery<PaginatedResponse<Category>, Error>({
    queryKey: ['CATALOGUE_CATEGORIES', selectedGroupId, keyProps],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getChildrenCategories(selectedGroupId!, pageParam, keyProps.environmentFilters);
      return data;
    },
    enabled: !!selectedGroupId,
    getNextPageParam,
    ...commonOptions,
    ...options,
  });
};

export const useClasses = (
  options: UseInfiniteQueryOptions<PaginatedResponse<Category>, Error> | undefined = {}
): UseInfiniteQueryResult<PaginatedResponse<Category>, Error> => {
  const { selectedCategoryId } = useCatalogueSlice(state => state.catalogueProperties);
  const keyProps = useCommonKeyProps();

  return useInfiniteQuery<PaginatedResponse<Category>, Error>({
    queryKey: ['CATALOGUE_CLASSES', selectedCategoryId, keyProps],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getChildrenCategories(selectedCategoryId!, pageParam, keyProps.environmentFilters);
      return data;
    },
    enabled: !!selectedCategoryId,
    getNextPageParam,
    ...commonOptions,
    ...options,
  });
};

export const useTypes = (
  options: UseInfiniteQueryOptions<PaginatedResponse<Category>, Error> | undefined = {}
): UseInfiniteQueryResult<PaginatedResponse<Category>, Error> => {
  const { selectedClassId } = useCatalogueSlice(state => state.catalogueProperties);
  const keyProps = useCommonKeyProps();

  return useInfiniteQuery<PaginatedResponse<Category>, Error>({
    queryKey: ['CATALOGUE_TYPES', selectedClassId, keyProps],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getChildrenCategories(selectedClassId!, pageParam, keyProps.environmentFilters);
      return data;
    },
    enabled: !!selectedClassId,
    getNextPageParam,
    ...commonOptions,
    ...options,
  });
};
