import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import Category from 'src/logic/models/catalogue/Category';
import { PaginatedResponse } from 'src/logic/models/queries';
import { getCategories, getClasses, getTypes } from 'src/logic/services/catalogue/classification';
import { CatalogueSearchResponse, getSearchCatalogue } from 'src/logic/services/catalogue/search';
import { commonOptions, useCommonKeyProps } from './common';

export const useSearchCatalogue = (
  search?: string,
  options: UseQueryOptions<CatalogueSearchResponse, Error> | undefined = {}
): UseQueryResult<CatalogueSearchResponse, Error> => {
  const keyProps = useCommonKeyProps();

  return useQuery<CatalogueSearchResponse, Error>({
    queryKey: ['CATALOGUE_SEARCH', keyProps, search],
    queryFn: async () => {
      const { data } = await getSearchCatalogue({
        search,
        environmentFilters: keyProps.environmentFilters,
      });
      return data;
    },
    ...commonOptions,
    ...options,
  });
};

// ================================= SEARCHED CLASSIFICATION =================================

const getNextPageParam = (lastPage: PaginatedResponse<Category>) => {
  return lastPage.meta.current_page < lastPage.meta.last_page ? lastPage.meta.current_page + 1 : undefined;
};

type UseClassificationSearchedItemsOptions = {
  search?: string;
  initialData?: PaginatedResponse<Category>;
  options?: UseInfiniteQueryOptions<PaginatedResponse<Category>, Error>;
};

const useClassificationSearchedItems = (
  KEY: 'CATEGORIES' | 'CLASSES' | 'TYPES',
  props: UseClassificationSearchedItemsOptions
): UseInfiniteQueryResult<PaginatedResponse<Category>, Error> => {
  const keyProps = useCommonKeyProps();
  const { search, options, initialData } = props;

  return useInfiniteQuery<PaginatedResponse<Category>, Error>({
    queryKey: [`SEARCH_${KEY}`, keyProps, search],
    queryFn: async ({ pageParam = 1 }) => {
      let fetchService;
      if (KEY === 'CATEGORIES') fetchService = getCategories;
      else if (KEY === 'CLASSES') fetchService = getClasses;
      else fetchService = getTypes;

      const { data } = await fetchService({
        page: pageParam,
        search,
        environmentFilters: keyProps.environmentFilters,
      });
      return data;
    },
    getNextPageParam,
    initialData: initialData ? { pages: [initialData], pageParams: [1] } : undefined,
    ...commonOptions,
    ...options,
  });
};

export const useSearchedCategories = (props: UseClassificationSearchedItemsOptions) => {
  return useClassificationSearchedItems('CATEGORIES', props);
};
export const useSearchedClasses = (props: UseClassificationSearchedItemsOptions) => {
  return useClassificationSearchedItems('CLASSES', props);
};
export const useSearchedTypes = (props: UseClassificationSearchedItemsOptions) => {
  return useClassificationSearchedItems('TYPES', props);
};
