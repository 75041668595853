import { Typography } from '@mui/material';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import EmptySection from '../../components/EmptySection';
import ErrorSection from '../../components/ErrorSection';
import LoadingSection from '../../components/LoadingSection';
import { useSearchCatalogue } from '../../queries/search';
import { useIsSearchState } from '../../utils/search-state';
import ClassificationSearchResults from '../@shared/classification-search-results/ClassificationSearchResults';
import SwiperBrands from '../@shared/swiperBrands';
import SwiperSellers from '../@shared/swiperSellers';

const SearchAllTabContent = () => {
  const { searchValue } = useCatalogueSlice(state => state.catalogueProperties);
  const isSearchState = useIsSearchState();
  const { data, isLoading, isError, refetch } = useSearchCatalogue(searchValue, { enabled: isSearchState });

  if (isLoading) {
    return <LoadingSection label='Loading' />;
  }
  if (isError) {
    return <ErrorSection refetch={refetch} />;
  }

  const isEmpty =
    data.groups.length === 0 &&
    data.categories.meta.total === 0 &&
    data.classes.meta.total === 0 &&
    data.types.meta.total === 0 &&
    data.products.length === 0 &&
    data.brands.length === 0 &&
    data.sellers.length === 0;

  if (isEmpty) {
    return <EmptySection />;
  }
  return (
    <>
      <Typography color='#3A3E50' fontSize={21} fontWeight={700} ml={1}>
        Search Results
      </Typography>

      <ClassificationSearchResults
        groups={data.groups}
        categories={data.categories}
        classes={data.classes}
        types={data.types}
        products={data.products}
      />

      {data.sellers && data.sellers.length > 0 && (
        <>
          <br />
          <Typography color='#8B8D98' fontSize={13} fontWeight={400} ml={1}>
            Sellers
          </Typography>
          <SwiperSellers sellers={data.sellers} />
        </>
      )}

      {data.brands && data.brands.length > 0 && (
        <>
          <br />
          <Typography color='#8B8D98' fontSize={13} fontWeight={400} ml={1}>
            Brands
          </Typography>
          <SwiperBrands brands={data.brands} />
        </>
      )}
    </>
  );
};

export default SearchAllTabContent;
