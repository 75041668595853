import { useQuery } from '@tanstack/react-query';
import { applyFilters } from 'src/logic/services/catalogue/advanced-filters';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import { commonOptions, useCommonKeyProps } from './common';

// ============================ Products & Services ============================
export const useTypeProductsAndServices = () => {
  const keyProps = useCommonKeyProps();
  const { selectedType } = useCatalogueSlice(state => state.catalogueProperties);

  return useQuery({
    queryKey: ['CATALOGUE_TYPES_PRODUCTS_&_SERVICES', selectedType?.id, keyProps],
    queryFn: async () => {
      if (selectedType === null) return [];
      const { data } = await applyFilters({
        advancedFiltersData: [
          { type: 'TYPE', filter_items: [{ id: selectedType.id, name: selectedType.name, selected: true }] },
        ],
        environmentFilters: keyProps.environmentFilters,
      });

      return data?.content?.data || [];
    },
    enabled: selectedType !== null,
    ...commonOptions,
  });
};

// ============================ Suggested Products & Services ============================
export const useSuggestedProductsAndServices = () => {
  const keyProps = useCommonKeyProps();

  return useQuery({
    queryKey: ['CATALOGUE_SUGGESTED_PRODUCTS_&_SERVICES', keyProps],
    queryFn: async () => {
      const { data } = await applyFilters({
        advancedFiltersData: null,
        environmentFilters: keyProps.environmentFilters,
      });
      return data?.content?.data || [];
    },
    ...commonOptions,
  });
};
