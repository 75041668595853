import { Typography } from '@mui/material';
import React from 'react';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import SortSelect from 'src/ui/components/dumb/shared/sort-select';
import EmptySection from '../../components/EmptySection';
import ErrorSection from '../../components/ErrorSection';
import LoadingSection from '../../components/LoadingSection';
import { useCatalogueBrandsTab } from '../../queries/brands';
import { useIsSearchState } from '../../utils/search-state';
import BrandsContent from './BrandsContent';

const SearchBrandsTabContent = () => {
  const { searchValue } = useCatalogueSlice(state => state.catalogueProperties);
  const isSearchState = useIsSearchState();
  const [sortBy, setSortBy] = React.useState<string>('');
  const { data, isLoading, isError, refetch } = useCatalogueBrandsTab(searchValue, { enabled: isSearchState });

  if (isLoading) {
    return <LoadingSection label='Loading Brands' />;
  }
  if (isError) {
    return <ErrorSection refetch={refetch} />;
  }

  const isEmpty = data.data.meta.total === 0;
  if (isEmpty) {
    return <EmptySection />;
  }
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography color='#3A3E50' fontSize={21} fontWeight={700} ml={1}>
          Search Results
        </Typography>
        <SortSelect sortConfig={data.sort_data} onSortItemSelected={setSortBy} />
      </div>

      <BrandsContent initialData={data} sortBy={sortBy} search={searchValue} />
    </>
  );
};

export default SearchBrandsTabContent;
