import Category from 'src/logic/models/catalogue/Category';
import CategoryCardWithIcon from '../../../components/CategoryCardWithIcon';
import { ProductCategoryKind } from '@/models';
import { useRouter } from 'next/router';
import { sm } from 'src/site-map';

type IProps = {
  group: Category;
  isSelected?: boolean;
  onClick?: Function;
};

const GroupCard = ({ group, isSelected, onClick }: IProps) => {
  const router = useRouter();

  const handleClick = (group_id: string | number, category_kind: number) => {
    router.push({
      pathname: sm.catalogue.searchResults.url,
      query: { 'category-kind': category_kind, 'group-id': group_id },
    });
  };

  return (
    <div
      style={{
        height: '100%',
        maxHeight: '100%',
        width: '220px',
      }}
    >
      <CategoryCardWithIcon
        category={group}
        isSelected={isSelected}
        onClick={onClick}
        variant='filled'
        onNumberOfProductsClick={() => handleClick(group.id, ProductCategoryKind.PRODUCT)}
        onNumberOfServicesClick={() => handleClick(group.id, ProductCategoryKind.SERVICE)}
      />
    </div>
  );
};

export default GroupCard;
