import React from 'react';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import ErrorSection from '../../../components/ErrorSection';
import LoadingSection from '../../../components/LoadingSection';
import { useTypes } from '../../../queries/classifications';
import GeneralCategoryList from './GeneralCategoryList';

const TypesSection = () => {
  const { data, isLoading, isError, refetch, hasNextPage, isFetchingNextPage, fetchNextPage } = useTypes();
  const { setSelectedType } = useCatalogueSlice(state => state.catalogueActions);
  const { selectedType } = useCatalogueSlice(state => state.catalogueProperties);

  const types = React.useMemo(() => {
    return data?.pages?.flatMap(page => page.data) ?? [];
  }, [data]);

  if (isLoading) {
    return <LoadingSection label='Loading Types' />;
  }
  if (isError) {
    return <ErrorSection refetch={refetch} />;
  }

  if (!data) return null;
  return (
    <GeneralCategoryList
      title='Type'
      items={types}
      selectedItemId={selectedType?.id}
      onSelect={typeItem => {
        setSelectedType(typeItem);
      }}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default TypesSection;
