import { useUserData } from '@/zustand';
import { Tab, Tabs } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { CatalogueTab } from 'src/logic/models/catalogue/catalogue-content';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import AddNewPrivateItemLink from '../shared/Add-private-item-link';
import SearchField from './components/SearchField';
import { useIsSearchState } from './utils/search-state';
import { useCatalogueTabs, useTabIndex } from './utils/tabs.config';

const spacing = '1rem';

const CatalogueContent = () => {
  const { isBuyer } = useUserData();

  // Tabs
  const { selectedTab } = useCatalogueSlice(state => state.catalogueProperties);
  const { setSelectedTab } = useCatalogueSlice(state => state.catalogueActions);
  const tabs = useCatalogueTabs();
  const tabIndex = useTabIndex();
  const router = useRouter();

  const handleChangeTab = (_event: React.SyntheticEvent, newTabIndex: number) => {
    setSelectedTab(tabs?.[newTabIndex]?.id || 'ALL');
  };

  useEffect(() => {
    if (router.query.selectedTab) {
      setSelectedTab(router.query.selectedTab as CatalogueTab);
    }
  }, [router.query.selectedTab, tabs]);

  const isSearchState = useIsSearchState();

  return (
    <div>
      <div style={{ paddingInline: spacing, paddingTop: spacing }}>
        <div style={{ marginBottom: spacing }}>
          <SearchField />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Tabs
            value={tabIndex}
            onChange={handleChangeTab}
            aria-label='Catalogue Tabs'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#3A3E50',
              },
            }}
          >
            {tabs.map(tab => (
            <Tab
            sx={{ textTransform: 'none', minWidth: 'auto', px: 2 }}
            label={
              <span
                style={{
                  color: tab.id === selectedTab ? '#3A3E50' : '#8B8D98',
                  fontWeight: tab.id === selectedTab ? 500 : 'normal',
                  display: 'flex', // Make the container a flexbox
                  alignItems: 'center', // Vertically align items
                  gap: '0.3rem', // Apply gap between icon and label
                }}
              >
                <span>{React.cloneElement(tab.icon, {
                  color: tab.id === selectedTab ? '#3A3E50' : '#8B8D98'
                })}</span>
                <span>{tab.label}</span>
              </span>
            }
            key={tab.id}
          />
          
            ))}
          </Tabs>

          {isBuyer() && <AddNewPrivateItemLink />}
        </div>
      </div>

      <div style={{ padding: spacing, boxShadow: '0px -4px 24px 4px #7090B014' }}>
        {tabs.map(tab => (
          <div role='tabpanel' hidden={selectedTab !== tab.id} key={tab.id}>
            {selectedTab === tab.id && <>{isSearchState ? tab.searchContent : tab.content}</>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CatalogueContent;
