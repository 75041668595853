import { Product, ProductCategoryKind } from '@/models';
import { Box, Tooltip, Typography } from '@mui/material';
import Link from 'next/link';
import React from 'react';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';
import { sm } from 'src/site-map';
import PrivacyButton from 'src/ui/components/shared/buttons/privacy-button';

type IProps = {
  product: Product;
};

const CatalogueProductCard = ({ product }: IProps) => {
  const typographyRef = React.useRef<HTMLSpanElement>(null);
  const { isCatalogueSearchPopupOpen } = useCatalogueSlice(state => state.catalogueProperties);
  const { closeCatalogueSearchPopup } = useCatalogueSlice(state => state.catalogueActions);

  return (
    <Box
      sx={{
        justifyContent: 'start',
        width: '175px',
        padding: '1rem',
        borderRadius: '8px',
        border: '1px solid #EAEEF6',
        transition: 'background-color 200ms',
        '&:hover': {
          backgroundColor: '#F5F8FD',
          borderColor: '#D0E0E8',
        },
      }}
    >
      <img src={product.image_url || ''} alt='' style={{ width: '100%', height: '100px', objectFit: 'contain' }} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '25px',
          marginBlock: '.35rem',
        }}
      >
        {product.category_kind === ProductCategoryKind.PRODUCT && product.brand && (
          <img
            src={product.brand.image_url}
            alt={product.brand.name}
            style={{ maxWidth: '65px', maxHeight: '24px', objectFit: 'contain' }}
          />
        )}
        {product.is_private_product && <PrivacyButton disableText />}
      </div>

      <Tooltip title={product.name}>
        <Link href={sm.catalogue.products.productId(`${product.id}`).url}>
          <Typography
            fontWeight={400}
            fontSize={13}
            color='#00385E'
            noWrap
            maxWidth={'100%'}
            className='cursor-pointer underline-on-hover'
            ref={typographyRef}
            onClick={() => {
              if (isCatalogueSearchPopupOpen) closeCatalogueSearchPopup();
            }}
          >
            {product.name}
          </Typography>
        </Link>
      </Tooltip>
    </Box>
  );
};

export default CatalogueProductCard;
