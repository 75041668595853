import { Button, Typography } from '@mui/material';

const ErrorSection = ({ refetch }: { refetch: Function }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '1rem',
        minHeight: '175px',
      }}
    >
      <Typography color='red' fontWeight={700} fontSize={15} textAlign={'center'}>
        An error occured
      </Typography>
      <Button type='button' onClick={() => refetch()} color='primary' variant='outlined'>
        Retry
      </Button>
    </div>
  );
};

export default ErrorSection;
