import { Button, Typography } from '@mui/material';

import { Product } from '@/models';
import MovingIcon from '@mui/icons-material/Moving';
import Link from 'next/link';
import { sm } from 'src/site-map';

import { Swiper, SwiperSlide } from 'swiper/react';
import CatalogueProductCard from '../../components/CatalogueProductCard';
import SectionDivider from '../../components/SectionDivider';
import { useSuggestedProductsAndServices } from '../../queries/products';
import { commonSwiperConfigs } from '../../utils/swiper.config';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';

const SuggestedProductsAndServicesSection = () => {
  const { data } = useSuggestedProductsAndServices();
  const { isCatalogueSearchPopupOpen } = useCatalogueSlice(state => state.catalogueProperties);
  const { closeCatalogueSearchPopup } = useCatalogueSlice(state => state.catalogueActions);

  if (data && data.length > 0) {
    return (
      <div style={{ width: '100%' }}>
        <SectionDivider />

        <div
          style={{
            display: 'flex',
            // justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
            marginBottom: '1rem',
          }}
        >
          <Typography color='#3A3E50' fontSize={13} fontWeight={500} ml={1}>
            <MovingIcon sx={{ fontSize: 16, mr: 0.5, transform: 'translateY(2px)' }} /> Suggested Products & Services
          </Typography>
          <Link href={sm.catalogue.searchResults.url}>
            <Button type='button' sx={{ color: '#0074FF' }}
              onClick={() => {
                if (isCatalogueSearchPopupOpen) closeCatalogueSearchPopup();
              }}
            >
              View All
            </Button>
          </Link>
        </div>

        <Swiper {...commonSwiperConfigs}>
          {data?.map((product: Product) => (
            <SwiperSlide key={product.id} style={{ width: 'fit-content' }}>
              <CatalogueProductCard product={product} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
  return null;
};

export default SuggestedProductsAndServicesSection;
