import { useUserData } from '@/zustand';
import React from 'react';
import useEnvironmentFilters from 'src/logic/zustand/Filters/hooks/use-environment-filters';

export const commonOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: Infinity,
  cacheTime: Infinity,
  retry: 0,
};

export const useCommonKeyProps = () => {
  const { userInfo } = useUserData();
  const userId = userInfo?.id;
  const environmentFilters = useEnvironmentFilters();
  return React.useMemo(() => ({ userId, environmentFilters }), [userId, environmentFilters]);
};
