import { SxProps } from '@mui/material';
import React from 'react';

export const brandsContainer: React.CSSProperties = {
  gap: '1rem',
  display: 'flex',
};
export const brandItem: SxProps = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: '200px',
  width: '100%',

  cursor: 'pointer',
};
export const brandImage: React.CSSProperties = {
  maxWidth: '80%',
  maxHeight: '80%',
  objectFit: 'contain',
};

export const categoryNameStyles: SxProps = {
  cursor: 'pointer',
  '&:hover': {
    fontWeight: 700,
  },
};

export const productsContainer: React.CSSProperties = {
  backgroundColor: '#fff',
  borderRadius: '8px',
};
export const iconContainer: React.CSSProperties = {
  backgroundColor: '#EAEEF6',
  borderRadius: '100%',
  width: '3rem',
  height: '3rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '1rem',
};

export const servicesContainer: React.CSSProperties = {
  gap: '1rem',
  display: 'flex',
};
export const serviceItem: SxProps = {
  backgroundColor: '#fff',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexDirection: 'column',
  maxHeight: '200px',
  width: '100%',
  padding: '1rem',

  cursor: 'pointer',
  maxWidth: '200px',
};
export const serviceImage: React.CSSProperties = {
  maxWidth: '3rem',
  maxHeight: '3rem',
  objectFit: 'contain',
};

export const sellersContainer = productsContainer;

export const companyAvatarStyles: React.CSSProperties = {
  backgroundColor: '#D0E0E8',
  color: '#3A3E50',
  transition: 'background-color 200ms, color 200ms',

  padding: '1.5em',
  fontWeight: 700,
  fontSize: '1rem',
};

export const sellerItemStyles: SxProps = {
  cursor: 'pointer',
  '&:hover': {
    '& .avatar': {
      backgroundColor: '#3A3E50',
      color: '#fff',
    },
    '& .seller-name': {
      fontWeight: 700,
    },
  },

  '& em': {
    color: '#0074FF',
    fontStyle: 'normal',
  },
};

export const catalogueBrandItem: SxProps = {
  p: 1.5,
  flexDirection: 'column',
  justifyContent: 'start',
  justifyItems: 'flex-start',
  alignItems: 'flex-start',
  backgroundColor: '#fff',
  borderRadius: '8px',
  display: 'flex',
  // maxHeight: '110px',
  width: '100%',
  cursor: 'pointer',
  transition: 'background-color 200ms',
  '&:hover': { backgroundColor: '#F6F8FC' },

  '& em': {
    color: '#0074FF',
    fontStyle: 'normal',
  },
};
