import { Button, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { getCategoryFullPath } from 'src/logic/helpers/get-category-full-path';
import Category from 'src/logic/models/catalogue/Category';
import { formatInteger } from 'src/logic/utils/numbers';
import { useCatalogueSlice } from 'src/logic/zustand/catalouge';

type IProps = {
  category: Category;

  isSelected?: boolean;
  onClick?: Function;
  variant?: 'filled' | 'outlined';

  onNumberOfProductsClick?: Function;
  onNumberOfServicesClick?: Function;

  maxTitleWidth?: string;
  cursor?: React.CSSProperties['cursor'];

  withFullPath?: boolean;
};

const stylesConfig = {
  filled: {
    normal: {
      cardBackgroundColor: '#fff',
      chipBackgroundColor: '#0074FF',
      titleColor: '#3A3E50',
      subTitleColor: '#8B8D98',
    },
    selected: {
      cardBackgroundColor: '#0074FF',
      chipBackgroundColor: '#fff',
      titleColor: '#fff',
      subTitleColor: '#fff',
    },
  },
  outlined: {
    normal: {
      cardBackgroundColor: '#fff',
      chipBackgroundColor: '#fff',
      titleColor: '#3A3E50',
      subTitleColor: '#8B8D98',
    },
    selected: {
      cardBackgroundColor: '#F4F7FE',
      chipBackgroundColor: '#fff',
      titleColor: '#3A3E50',
      subTitleColor: '#8B8D98',
    },
  },
};

const CategoryCardWithIcon = ({
  category,
  isSelected,
  onClick,
  variant = 'filled',
  onNumberOfProductsClick,
  onNumberOfServicesClick,
  maxTitleWidth = '150px',
  cursor = 'pointer',
  withFullPath = false,
}: IProps) => {
  const typographyRef = React.useRef<HTMLSpanElement>(null);
  const selectionState = isSelected ? 'selected' : 'normal';
  const styles = React.useMemo(() => stylesConfig[variant][selectionState], [variant, selectionState]);
  const { isCatalogueSearchPopupOpen } = useCatalogueSlice(state => state.catalogueProperties);
  const { closeCatalogueSearchPopup } = useCatalogueSlice(state => state.catalogueActions);
  const fullPath = React.useMemo(() => {
    if (!withFullPath) return '';
    return getCategoryFullPath(category);
  }, [category, withFullPath]);

  return (
    <Button
      sx={{
        height: '100%',
        maxHeight: '100%',
        justifyContent: 'start',
        width: '100%',
        padding: '.5rem',
        display: 'flex',
        alignItems: 'center',
        gap: '.5rem',
        borderRadius: '8px',
        cursor,

        transition: 'opacity 200ms, background-color 200ms',
        backgroundColor: styles.cardBackgroundColor,
        '&:hover': {
          backgroundColor: styles.cardBackgroundColor,
          opacity: 0.9,
        },

        '& em': {
          color: '#0074FF',
          fontStyle: 'normal',
        },
      }}
      onClick={() => onClick?.(category.id)}
    >
      <div
        style={{
          backgroundColor: styles.chipBackgroundColor,
          transition: 'background-color 200ms',
          border: '1px solid #0074FF',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          width: '36px',
          height: '36px',
          flexShrink: 0,
        }}
      >
        <img src={category.image_url || ''} alt='' style={{ width: '16px', height: '16px', objectFit: 'contain' }} />
      </div>

      <div>
        {withFullPath && (
          <Tooltip title={fullPath}>
            <Typography
              fontWeight={400}
              fontSize={11}
              color={styles.subTitleColor}
              noWrap
              maxWidth={maxTitleWidth}
              textAlign={'start'}
              ref={typographyRef}
            >
              {fullPath}
            </Typography>
          </Tooltip>
        )}

        <Tooltip title={category.name}>
          <Typography
            fontWeight={400}
            fontSize={13}
            color={styles.titleColor}
            noWrap
            maxWidth={maxTitleWidth}
            textAlign={'start'}
            ref={typographyRef}
          >
            {category?.search_metadata?._highlightResult ? (
              <span dangerouslySetInnerHTML={{ __html: category?.search_metadata._highlightResult.title.value }}></span>
            ) : (
              category.name
            )}
          </Typography>
        </Tooltip>

        <Typography
          color={styles.subTitleColor}
          fontSize={12}
          fontWeight={400}
          textAlign={'start'}
          sx={{ opacity: 0.8 }}
        >
          <span
            onClick={e => {
              e.stopPropagation();
              isCatalogueSearchPopupOpen && closeCatalogueSearchPopup()
              category.number_of_products && category.number_of_products !== 0 && onNumberOfProductsClick?.();
            }}
            style={{ cursor: category.number_of_products !== 0 ? 'pointer' : 'default' }}
            className={category.number_of_products !== 0 ? 'underline-on-hover' : ''}
          >
            {formatInteger(category.number_of_products)} Products
          </span>
          <span
            onClick={e => {
              e.stopPropagation();
              isCatalogueSearchPopupOpen && closeCatalogueSearchPopup()
              category.number_of_services && category.number_of_services !== 0 && onNumberOfServicesClick?.();
            }}
            style={{ cursor: category.number_of_services !== 0 ? 'pointer' : 'default', paddingInlineStart: '.5rem' }}
            className={category.number_of_services !== 0 ? 'underline-on-hover' : ''}
          >
            {formatInteger(category.number_of_services)} Services
          </span>
        </Typography>
      </div>
    </Button>
  );
};

export default CategoryCardWithIcon;
