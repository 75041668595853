import { Product, ProductCategoryKind } from '@/models';
import { Button, Grid, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import Category from 'src/logic/models/catalogue/Category';
import { PaginatedResponse } from 'src/logic/models/queries';
import { sm } from 'src/site-map';
import { Swiper, SwiperSlide } from 'swiper/react';
import CategoryCardWithIcon from '../../../components/CategoryCardWithIcon';
import ProductSearchCard from '../../../components/ProductSearchCard';
import { commonSwiperConfigs } from '../../../utils/swiper.config';
import CategoriesSearchResults from './CategoriesSearchResults';
import ClassesSearchResults from './ClassesSearchResults';
import TypesSearchResults from './TypesSearchResults';

type IProps = {
  groups: Category[];
  categories: PaginatedResponse<Category>;
  classes: PaginatedResponse<Category>;
  types: PaginatedResponse<Category>;
  products: Product[];
};

const ClassificationSearchResults = ({ groups, categories, classes, types, products }: IProps) => {
  const shouldShowGroupsSection = groups.length > 0;
  const shouldShowClassificationSection = categories.meta.total > 0 || classes.meta.total > 0 || types.meta.total > 0;
  const shouldShowProductsSection = products.length > 0;
  const { push } = useRouter();
  const handleClick = (group_id: string | number, category_kind: number) => {
    push({
      pathname: sm.catalogue.searchResults.url,
      query: { 'category-kind': category_kind, 'group-id': group_id },
    });
  };

  return (
    <>
      <br />

      {shouldShowGroupsSection && (
        <>
          <Typography color='#8B8D98' fontSize={13} fontWeight={400} ml={1}>
            Group
          </Typography>
          <Swiper {...commonSwiperConfigs} style={{ paddingBottom: '1rem', marginBottom: '1rem' }}>
            {groups.map(group => (
              <SwiperSlide key={group.id} style={{ width: 'fit-content' }}>
                <div style={{ width: '220px' }}>
                  <CategoryCardWithIcon
                    category={group}
                    variant='filled'
                    cursor='default'
                    onNumberOfProductsClick={() => handleClick(group.id, ProductCategoryKind.PRODUCT)}
                    onNumberOfServicesClick={() => handleClick(group.id, ProductCategoryKind.SERVICE)}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}

      {shouldShowClassificationSection && (
        <Grid container mb={0.25} spacing={4}>
          <Grid item md={4}>
            <CategoriesSearchResults categories={categories} />
          </Grid>
          <Grid item md={4}>
            <ClassesSearchResults classes={classes} />
          </Grid>
          <Grid item md={4}>
            <TypesSearchResults types={types} />
          </Grid>
        </Grid>
      )}

      {shouldShowProductsSection && (
        <div style={{ marginTop: '1rem' }}>
          <div
            style={{
              marginBottom: '.5rem', display: 'flex',
              // justifyContent: 'space-between',
              alignItems: 'center', textAlign: 'center'
            }}
          >
            <Typography color='#8B8D98' fontSize={13} fontWeight={400} mx={1}>
              Products & Services
            </Typography>
            <Button type='button' sx={{ color: '#0074FF', pt: 0 }}>
              View All
            </Button>
          </div>
          <div style={{ maxHeight: '300px', overflow: 'auto', paddingInlineEnd: '.5rem' }} className='styled-scrollbar'>
            {products.map(product => (
              <ProductSearchCard key={product.id} product={product} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ClassificationSearchResults;
