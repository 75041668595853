import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { Typography } from '@mui/material';
import React from 'react';
import SortSelect from 'src/ui/components/dumb/shared/sort-select';
import CatalogueFeaturedSellerCard from '../../components/CatalogueFeaturedSellerCard';
import ErrorSection from '../../components/ErrorSection';
import LoadingSection from '../../components/LoadingSection';
import SectionDivider from '../../components/SectionDivider';
import { useCatalogueSellersTab } from '../../queries/sellers';
import SellersContent from './SellersContent';

const SellersTabContent = () => {
  const [sortBy, setSortBy] = React.useState<string>('');
  const { data, isLoading, isError, refetch } = useCatalogueSellersTab();

  if (isLoading) {
    return <LoadingSection label='Loading Sellers' />;
  }
  if (isError) {
    return <ErrorSection refetch={refetch} />;
  }

  if (!data) return null;
  return (
    <div>
      {data?.featured_sellers && data.featured_sellers.length > 0 && (
        <>
          <Typography color='#3A3E50' fontSize={21} fontWeight={700} ml={1}>
            Featured Sellers
          </Typography>

          <div
            style={{
              marginBlock: '1rem',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))',
              gap: '1rem',
            }}
          >
            {data.featured_sellers?.map(seller => (
              <CatalogueFeaturedSellerCard key={seller.seller.id} seller={seller} />
            ))}
          </div>

          <SectionDivider />
        </>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography color='#3A3E50' fontSize={13} fontWeight={500} ml={1}>
          <LocalOfferOutlinedIcon sx={{ fontSize: '16px', transform: 'translateY(3px)' }} /> All Sellers
        </Typography>
        <SortSelect sortConfig={data.sort_data} onSortItemSelected={setSortBy} />
      </div>

      <SellersContent initialData={data} sortBy={sortBy} />
    </div>
  );
};

export default SellersTabContent;
